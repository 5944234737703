// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: '메인화면',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill')
  },
  {
    title: '모집공고',
    path: '/dashboard/RecruitmentNotice',
    icon: getIcon('eva:shopping-bag-fill')
  },
  // {
  //   title: '로그인',
  //   path: '/login',
  //   icon: getIcon('eva:lock-fill')
  // },
  // {
  //   title: '회원가입',
  //   path: '/register',
  //   icon: getIcon('eva:person-add-fill')
  // },
  // {
  //   title: '기업 회원가입',
  //   path: '/companyRegister',
  //   icon: getIcon('eva:person-add-fill')
  // },
  // {
  //   title: '공사관리',
  //   path: '/dashboard/Construction',
  //   icon: getIcon('eva:shopping-bag-fill')
  // },
  // {
  //   title: '인력관리',
  //   // path: '/humanManegement',
  //   path: '/dashboard/user',
  //   icon: getIcon('eva:shopping-bag-fill')
  // },
  {
    title: '장비모니터링',
    path: '/monitoringManagement',
    icon: getIcon('eva:shopping-bag-fill')
  },
  // {
  //   title: '가계부',
  //   path: '/dashboard/payCalculate',
  //   icon: getIcon('eva:shopping-bag-fill')
  // },
  {
    title: 'MyPage',
    path: '/mypage',
    icon: getIcon('eva:settings-fill')
  }
];

export default sidebarConfig;
