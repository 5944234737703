import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Stack, Button, Container, Typography, InputLabel } from '@mui/material';
// components
import { DataGrid } from '@mui/x-data-grid';
// import { GridCellParams, GridCellValue, GridCellProps } from '@material-ui/data-grid';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Page from '../components/Page';
// import { array } from 'yup/lib/locale';
// import SearchNotFound from '../components/SearchNotFound';
// ----------------------------------------------------------------------
// 순서
// 클래스의 경우 -> constructor -> ref -> componentDidMount
// -> (setState/props 바뀔때 -> shouldComponentUpdate -> render -> componentDidUpdate)
// 부모가 나를 없앴을 때 -> componentWillUnmount -> 소멸
// componentDidMount() { 컴포넌트가 첫 렌더링된 후
// };
// componentDidUpdate() { 리렌더링 후
// };
// componentWillUnmount() { 컴포넌트가 제거되기 직전
// };
// ----------------------------------------------------------------------
// function handleSearch() {

// }
export default function RecruitmentNotice() {
  // console.log(sessionStorage.getItem('token'));
  // const [newRecuitNoticeList, setNewRecuitNoticeList] = useState([]);
  const navigate = useNavigate();
  const [value, setValue] = useState('1');
  const [myWorkList, setMyWorkList] = useState([]);
  const [myRegWorkList, setMyRegWorkList] = useState([]);
  const [myReqWorkList, setMyReqWorkList] = useState([]);
  const [recuitmentRegOpen, setRecuitmentRegOpen] = useState(false);
  const [searchEquitmentNm, setSearchEquitmentNm] = useState('');
  const [searchWorkArea, setSearchWorkArea] = useState('');
  // 한달전 날짜 설정
  const oneMonthAgo = new Date();
  const year = oneMonthAgo.getFullYear();
  const month = oneMonthAgo.getMonth();
  const day = oneMonthAgo.getDate();
  // Dialog Parameter
  // const [workName, setWorkName] = useState(); // 작업명
  const [equitment, setEquitment] = useState(); // 장비명
  // const [place, setPlace] = useState(); // 작업장소
  const [address, setAddress] = useState(); // 작업주소
  // const [area, setArea] = useState(); // 작업지역
  const [workStartDate, setWorkStartDate] = useState(); // 작업시작일
  const [workEndtDate, setWorkEndDate] = useState(); // 작업종료일
  const [workContents, setWorkContents] = useState(); // 작업내용
  const [workPrice, setWorkPrice] = useState(); // 작업금액
  const [howtoPay, setHowtoPay] = useState(); // 지불방법
  const [memo, setMemo] = useState();
  const [employer, setEmployer] = useState(); // 발주처
  const [wTime, setWtime] = useState(); // 작업시간
  const [workBeginTime, setWorkBeginTime] = useState(); // 작업시작시간
  const [workEndTime, setWorkEndTime] = useState(); // 작업종료시간
  const [status, setStatus] = useState('I');
  const NEW_TABLE_HEAD = [
    {
      field: 'id',
      headerName: '번호',
      align: 'center',
      width: 10
    },
    { field: 'eqpmnNm', headerName: '장비명', alignRight: false, width: 150 },
    { field: 'workAdres', headerName: '작업지역', type: 'date', alignRight: false, width: 200 },
    { field: 'workBegin', headerName: '작업시작일', alignRight: false },
    { field: 'workEndde', headerName: '작업종료일', alignRight: false },
    { field: 'workCn', headerName: '작업내용', alignRight: false, width: 250 },
    {
      field: 'workMemo',
      headerName: '메모',
      alignRight: false,
      width: 150
    },
    { field: 'pymntMthd', headerName: '지불방식', alignRight: false, width: 100 },
    {
      field: 'workAmount',
      headerName: '단가(금액)',
      type: 'number',
      alignRight: false,
      width: 100
    },
    {
      field: 'workSttus',
      headerName: '진행상태',
      alignRight: false,
      width: 100,
      valueGetter: (params) => (params.row.workSttus === 'I' ? '모집중' : '마감')
    },
    { field: 'workOrnt', headerName: '등록자', alignRight: false, width: 100 },
    // { field: 'workTotamt', headerName: '지원현황', type: 'number', alignRight: false },
    // { field: 'regUser', headerName: '등록자', align: 'center', width: 70 },
    {
      field: 'regDate',
      headerName: '등록일',
      align: 'center',
      type: 'date',
      width: 150,
      valueGetter: (params) => params.row.regDate.substr(0, 16)
      // valueGetter: (params) => (params.row.regDate)
    },
    { field: 'workNo', headerName: 'workNo', align: 'center', hide: true }
  ];
  // ------------------------------------------------------------------------------------------------------------
  const MYWORK_TABLE_HEAD = [
    {
      field: 'id',
      headerName: '번호',
      align: 'center',
      width: 10
      // valueGetter: (params) => params.row.index
    },
    { field: 'eqpmnNm', headerName: '장비명', alignRight: false, width: 150 },
    { field: 'workAdres', headerName: '작업지역', alignRight: false, width: 200 },
    { field: 'workBegin', headerName: '작업시작일', type: 'date', alignRight: false },
    { field: 'workEndde', headerName: '작업종료일', type: 'date', alignRight: false },
    { field: 'workCn', headerName: '작업내용', alignRight: false, width: 250 },
    {
      field: 'workMemo',
      headerName: '메모',
      align: 'center',
      width: 150
    },
    { field: 'pymntMthd', headerName: '지불방식', alignRight: false, width: 70 },
    { field: 'workAmount', headerName: '단가(금액)', type: 'number', alignRight: false },
    {
      field: 'workSttus',
      headerName: '진행상태',
      alignRight: false,
      width: 70,
      valueGetter: (params) => (params.row.workSttus === 'I' ? '모집중' : '마감')
    },
    // { field: 'workTotamt', headerName: '지원현황', type: 'number', alignRight: false },
    { field: 'workOrnt', headerName: '등록자', alignRight: false, width: 150 },
    // { field: 'regUser', headerName: '등록자', alignRight: false, width: 70, hide: true },
    {
      field: 'regDate',
      headerName: '등록일',
      align: 'center',
      width: 120,
      valueGetter: (params) => params.row.regDate.substr(0, 16)
    },
    { field: 'workNo', headerName: 'workNo', alignRight: false, hide: true }
  ];
  // ------------------------------------------------------------------------------------------------------------
  const MYREG_TABLE_HEAD = [
    {
      field: 'id',
      headerName: '번호',
      align: 'center',
      width: 10,
      valueGetter: (params) => params.row.workNo
    },
    { field: 'eqpmnNm', headerName: '장비명', alignRight: false, width: 150 },
    { field: 'workAdres', headerName: '작업지역', alignRight: false, width: 200 },
    { field: 'workBegin', headerName: '작업시작일', type: 'date', alignRight: false },
    { field: 'workEndde', headerName: '작업종료일', type: 'date', alignRight: false },
    { field: 'workCn', headerName: '작업내용', alignRight: false, width: 250 },
    {
      field: 'workMemo',
      headerName: '메모',
      type: 'number',
      align: 'center',
      width: 150
    },
    { field: 'pymntMthd', headerName: '지불방식', type: 'number', align: 'center', width: 70 },
    { field: 'workAmount', headerName: '단가(금액)', type: 'number', alignRight: false },
    {
      field: 'workSttus',
      headerName: '진행상태',
      type: 'number',
      alignRight: false,
      width: 70,
      valueGetter: (params) => (params.row.workSttus === 'I' ? '모집중' : '마감')
    },
    // { field: 'workTotamt', headerName: '지원현황', type: 'number', alignRight: false },
    { field: 'workOrnt', headerName: '등록자', align: 'center', width: 150 },
    { field: 'regUser', headerName: '등록자', align: 'center', width: 70, hide: true },
    {
      field: 'regDate',
      headerName: '등록일',
      align: 'center',
      width: 120,
      valueGetter: (params) => params.row.regDate.substr(0, 16)
    },
    { field: 'workNo', headerName: 'workNo', align: 'center', hide: true }
  ];
  // -----------------------------------------------API 호출-----------------------------------------------------
  // 목록 API 호출
  const WorkListGetData = async () => {
    // const apiUrl = 'http://localhost:8080/workpblanc/getWorkList';
    const apiUrl = 'https://site.yeonisoft.com/workpblanc/getWorkList';
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      eqpmnNm: searchEquitmentNm,
      workAdres: searchWorkArea
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        setMyWorkList(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 내가 등록한 공고 목록
  const myWorkListGetData = async () => {
    console.log(`${sessionStorage.getItem('id')}`);
    // const apiUrl = 'http://localhost:8080/manager/workpblanc/getMyWorkListApp';
    const apiUrl = 'https://site.yeonisoft.com/manager/workpblanc/getMyWorkListApp';
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      regUser: `${sessionStorage.getItem('id')}`
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        setMyRegWorkList(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 내가 신청한 공고 목록
  const myReqListGetData = async () => {
    console.log(`${sessionStorage.getItem('id')}`);
    // const apiUrl = 'http://localhost:8080/workpblanc/getMyWorkApplyListApp';
    const apiUrl = 'https://site.yeonisoft.com/workpblanc/getMyWorkApplyListApp';
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      regUser: `${sessionStorage.getItem('id')}`
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        setMyReqWorkList(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 등록 API 호출
  const getSave = async () => {
    // const apiUrl = 'http://localhost:8080/workpblanc/regWorkpblanc';
    const apiUrl = 'https://site.yeonisoft.com/workpblanc/regWorkpblanc';
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      // workNm: workName,
      eqpmnNm: equitment,
      // workPlace: place,
      workAdres: address,
      // workArea: area,
      workBegin: workStartDate,
      workEndde: workEndtDate,
      workCn: workContents,
      workAmount: workPrice,
      pymntMthd: howtoPay,
      workMemo: memo,
      workOrnt: employer,
      workTime: wTime,
      beginTime: workBeginTime,
      enddeTime: workEndTime,
      workSttus: status,
      delYn: 'N'
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        setMyWorkList(response.data);
        console.log(response.data);
        if (response.data.code === '200') {
          window.alert('저장되었습니다');
          WorkListGetData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // -----------------------------------------------API 호출-----------------------------------------------------
  // -----------------------------------------------useEffect----------------------------------------------------
  useEffect(() => {
    // 토큰 값이 없을 경우 Login Page로 이동
    if (sessionStorage.getItem('token') === undefined || sessionStorage.getItem('token') === null) {
      navigate('/login');
    }
    WorkListGetData();
  }, []);
  // ------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------Event-------------------------------------------------------
  // const onCellDoubleClick = (params) => {
  //   console.log(params.row);
  // };
  const onClickSearch = (e) => {
    // setSearchWord(e.target.value);
    // setStartDate();
    // setEndDate();
    console.log(e.target.value);
    WorkListGetData();
  };
  const onChangeEquitNm = (e) => {
    setSearchEquitmentNm(e.target.value);
  };
  const onChangeWorkArea = (e) => {
    setSearchWorkArea(e.target.value);
  };
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      onClickSearch();
    }
  };
  // ------------------------------------------------Event-------------------------------------------------------
  // 등록 Dialog Open
  const handleRegDialogOpen = () => {
    setRecuitmentRegOpen(true);
  };
  // 등록 Dialog Close
  const handleRegDialogClose = () => {
    // setWorkName();
    setEquitment();
    // setPlace();
    setAddress();
    // setArea();
    setWorkStartDate();
    setWorkEndDate();
    setWorkContents();
    setWorkPrice();
    setHowtoPay();
    setMemo();
    setEmployer();
    setWtime();
    setWorkBeginTime();
    setWorkEndTime();
    setStatus();
    setRecuitmentRegOpen(false);
  };
  // 등록 저장 APi 호출
  const handleRegSave = () => {
    console.log('저장 API 호출');
    getSave();
    handleRegDialogClose();
  };
  // ------------------------------------------------Event-------------------------------------------------------
  // Change Event
  // const onChangePlace = (e) => {
  //   setPlace(e.target.value);
  // };
  // const onChangeWorkName = (e) => {
  //   setWorkName(e.target.value);
  // };
  const onChangeAddress = (e) => {
    setAddress(e.target.value);
  };
  // const onChangeArea = (e) => {
  //   setArea(e.target.value);
  // };
  const onChangeWorkContents = (e) => {
    setWorkContents(e.target.value);
  };
  const onChangeEquitment = (e) => {
    setEquitment(e.target.value);
  };
  const onChangeWorkPrice = (e) => {
    setWorkPrice(e.target.value);
  };
  const onChangeStatus = (e) => {
    setStatus(e.target.value);
  };
  const onChangeWorkStartDate = (e) => {
    setWorkStartDate(e.target.value);
  };
  const onChangeWorkEndDate = (e) => {
    setWorkEndDate(e.target.value);
  };
  const onChangeHowtoPay = (e) => {
    setHowtoPay(e.target.value);
  };
  const onChangeMemo = (e) => {
    setMemo(e.target.value);
  };
  const onChangeEmployer = (e) => {
    setEmployer(e.target.value);
  };
  const onChangeWtime = (e) => {
    setWtime(e.target.value);
  };
  const onChangeWorkBeginTime = (e) => {
    setWorkBeginTime(e.target.value);
  };
  const onChangeWorkEndTime = (e) => {
    setWorkEndTime(e.target.value);
  };
  // -----------------------------------------------------------------------------------------------------------
  const handleChangeTab = (e, newValue) => {
    setValue(newValue);
    if (newValue === '1') {
      console.log(newValue);
    } else if (newValue === '2') {
      console.log(newValue);
      myWorkListGetData();
    } else if (newValue === '3') {
      console.log(newValue);
      myReqListGetData();
    }
  };
  // -----------------------------------------------------------------------------------------------------------
  return (
    <Page title="모집공고">
      <Box spacing={1}>
        <Stack justifyContent="flex-start">
          <Typography variant="h3" gutterBottom>
            모집공고
          </Typography>
        </Stack>
        <TabContext value={value} spacing={0.5}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab}>
              <Tab label="신규공고목록" value="1" />
              <Tab label="내가등록한목록" value="2" />
              <Tab label="내가신청한목록" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0.5}>
              <Stack direction="row" spacing={1}>
                <TextField
                  fullWidth
                  label="장비명"
                  type="search"
                  size="small"
                  value={searchEquitmentNm}
                  onChange={onChangeEquitNm}
                  // onKeyDown={onkeydown}
                />
                <TextField
                  fullWidth
                  label="작업장소"
                  type="search"
                  size="small"
                  value={searchWorkArea}
                  onChange={onChangeWorkArea}
                  // onKeyDown={onkeydown}
                />
              </Stack>
              {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack direction="row" spacing={1}>
                    <DatePicker
                      // sx={{ ml: 1, width: 20, height: 20 }}
                      // size="small"
                      mask="____-__-__"
                      value={startDate}
                      inputFormat="yyyy-MM-dd"
                      selectStart
                      // onChange={(newValue) => setStartDate(newValue)}
                      onChange={(e) => setStartDate(e.target.value)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider> */}
              <Stack direction="row" spacing={1} mb={1}>
                <Button variant="outlined" size="small" onClick={handleRegDialogOpen}>
                  공고등록
                </Button>
                <Dialog
                  open={recuitmentRegOpen}
                  onClose={handleRegDialogClose}
                  PaperProps={{ sx: { width: '50%', height: '85%' } }}
                >
                  <DialogTitle align="center">공고등록</DialogTitle>
                  <DialogContent>
                    <Stack direction="column" justifyContent="space-between" spacing={3}>
                      <TextField
                        fullWidth
                        label="장비명"
                        type="text"
                        variant="standard"
                        value={equitment || ''}
                        onChange={onChangeEquitment}
                      />
                      <TextField
                        label="작업주소"
                        type="text"
                        variant="standard"
                        value={address || ''}
                        onChange={onChangeAddress}
                      />
                      <TextField
                        label="작업내용"
                        type="text"
                        variant="standard"
                        value={workContents || ''}
                        onChange={onChangeWorkContents}
                      />
                      <Stack direction="row" justifyContent="space-between" spacing={1}>
                        <TextField
                          fullWidth
                          label="작업금액"
                          type="number"
                          variant="standard"
                          value={workPrice || ''}
                          onChange={onChangeWorkPrice}
                        />
                        <TextField
                          fullWidth
                          label="지불방법"
                          type="text"
                          variant="standard"
                          value={howtoPay || ''}
                          onChange={onChangeHowtoPay}
                        />
                      </Stack>
                      <Stack direction="row" justifyContent="space-between" spacing={1}>
                        <TextField
                          fullWidth
                          label="발주기관"
                          type="text"
                          variant="standard"
                          value={employer || ''}
                          onChange={onChangeEmployer}
                        />
                        <TextField
                          fullWidth
                          label="총작업시간"
                          type="number"
                          variant="standard"
                          value={wTime || ''}
                          onChange={onChangeWtime}
                        />
                      </Stack>
                      <br />

                      <Stack direction="row" justifyContent="space-between" spacing={1}>
                        <TextField
                          fullWidth
                          type="date"
                          variant="standard"
                          value={workStartDate || ''}
                          onChange={onChangeWorkStartDate}
                        />
                        <TextField
                          fullWidth
                          type="date"
                          variant="standard"
                          value={workEndtDate || ''}
                          onChange={onChangeWorkEndDate}
                        />
                      </Stack>

                      <Stack direction="row" justifyContent="space-between" spacing={1}>
                        <TextField
                          fullWidth
                          label="시작시간"
                          type="time"
                          variant="standard"
                          value={workBeginTime || ''}
                          onChange={onChangeWorkBeginTime}
                        />
                        <TextField
                          fullWidth
                          label="종료시간"
                          type="time"
                          variant="standard"
                          value={workEndTime || ''}
                          onChange={onChangeWorkEndTime}
                        />
                      </Stack>
                      <FormControl>
                        <FormLabel>상태</FormLabel>
                        <RadioGroup row>
                          <FormControlLabel
                            checked={status === 'I'}
                            onChange={onChangeStatus}
                            value={status}
                            control={<Radio />}
                            label="모집중"
                          />
                          <FormControlLabel
                            checked={status !== 'I'}
                            onChange={onChangeStatus}
                            value={status}
                            control={<Radio />}
                            label="마감"
                          />
                        </RadioGroup>
                      </FormControl>
                      <TextField
                        label="메모"
                        type="text"
                        variant="standard"
                        value={memo || ''}
                        onChange={onChangeMemo}
                      />
                      <br />
                    </Stack>
                    <Stack direction="row" justifyContent="center" spacing={1}>
                      <Button variant="contained" onClick={handleRegSave}>
                        저장
                      </Button>
                      <Button variant="contained" onClick={handleRegDialogClose}>
                        취소
                      </Button>
                    </Stack>
                  </DialogContent>
                </Dialog>
                <Button variant="contained" onClick={onClickSearch} onKeyPress={onKeyPress}>
                  검색
                </Button>
              </Stack>
            </Stack>
            <div style={{ height: 600, width: '100%' }}>
              <DataGrid
                rows={myWorkList}
                columns={NEW_TABLE_HEAD}
                rowHeight={40}
                // getRowId={(row) => row.workNo}
                // onCellClick={onCellDoubleClick}
                disableColumnMenu
              />
            </div>
          </TabPanel>
          <TabPanel value="2">
            <div style={{ height: 600, width: '100%' }}>
              <DataGrid
                rows={myRegWorkList}
                columns={MYWORK_TABLE_HEAD}
                getRowId={(row) => row.workNo}
                rowHeight={40}
                // onCellClick={onCellDoubleClick}
                disableColumnMenu
              />
            </div>
          </TabPanel>
          <TabPanel value="3">
            <div style={{ height: 600, width: '100%' }}>
              <DataGrid
                rows={myReqWorkList}
                columns={MYREG_TABLE_HEAD}
                getRowId={(row) => row.workNo}
                rowHeight={40}
                // onCellClick={onCellDoubleClick}
                disableColumnMenu
              />
            </div>
          </TabPanel>
        </TabContext>
      </Box>
    </Page>
  );
}
