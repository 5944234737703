import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
// material
import { Stack, Button, Typography, Container, TextField, Link, Grid } from '@mui/material';
// components
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Page from '../components/Page';
import AuthLayout from '../layouts/AuthLayout';
// import AccountPopover from '../layouts/dashboard/AccountPopover';
// import { ConstructionSecondCell, ConstructionThirdCell } from '../sections/construction';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

// ----------------------------------------------------------------------------------------------------
const FIRST_TABLE_HEAD = [
  { field: 'id', headerName: '번호', width: 50, align: 'center' },
  { field: 'cntrwkmNm', headerName: '현장명', width: 100 },
  { field: 'cntrwkmSDate', headerName: '공사기간', width: 90 },
  { field: 'cntrwkmEDate', headerName: '공사기간', width: 90 },
  {
    field: 'cntrwkmSttus',
    headerName: '상태',
    width: 100,
    valueGetter: (params) => (params.row.cntrwkmSttus === 'I' ? '공사중' : '마감')
  }
];
const SECOND_TABLE_HEAD = [
  { field: 'id', headerName: '번호', align: 'center', width: 10 },
  { field: 'cntrwkmKnd', headerName: '공종', alignRight: false },
  { field: 'cntrwkmGnr', headerName: '위치', alignRight: false, width: 150 },
  { field: 'cntrwkmDailyDate', headerName: '날짜', alignRight: false },
  { field: 'regUser', headerName: '작성자', alignRight: false },
  {
    field: 'cntrwkmDailyEtc',
    headerName: '특이사항',
    alignRight: false,
    width: 450
  }
];
const THIRD_TABLE_HEAD = [
  { field: 'fairNo', headerName: '공정번호', align: 'center', width: 60 },
  { field: 'workNm', headerName: '작업내용', alignRight: false, width: 170 }
];
const FORTH_TABLE_HEAD = [
  { field: 'id', headerName: '번호', align: 'center', width: 10 },
  { field: 'fairNo', headerName: '공정', align: 'center', width: 15 },
  { field: 'workKdd', headerName: '구분', alignRight: false, width: 80 },
  { field: 'workPrdlst', headerName: '품목/이름', alignRight: false },
  { field: 'workEntrps', headerName: '업체명', alignRight: false },
  // { field: 'workStndrd', headerName: '규격', alignRight: false, width: 15 },
  { field: 'workQy', headerName: '수량', alignRight: false, width: 10 },
  {
    field: 'workAmount',
    headerName: '금액',
    alignRight: false,
    type: 'number',
    width: 80
  },
  {
    field: 'workTxtamt',
    headerName: '세액',
    alignRight: false,
    type: 'number',
    width: 80
  },
  { field: 'workDetail', headerName: '상세내역', alignRight: false },
  { field: 'workRm', headerName: '비고', alignRight: false }
];
// ----------------------------------------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 600,
  // marginLeft: '35%',
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: theme.spacing(10, 0)
}));
const ContentStyle2 = styled('div')(({ theme }) => ({
  // maxWidth: 600,
  // marginright: '35%',
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: theme.spacing(10, 0)
}));

export default function ConstructorRegistration() {
  const [firstConstructionList, setFirstConstructionList] = useState([]);
  const [secondConstructionList, setSecondConstructionList] = useState([]);
  const [thirdConstructionList, setThirdConstructionList] = useState([]);
  const [forthConstructionList, setForthConstructionList] = useState([]);
  // API Parameter 값 설정
  const [sendWorkNo, setSendWokrNo] = useState(); // 공사번호
  const [sendDailyNo, setSendDailyNo] = useState(); // 공사일보 고유번호
  const [sendFairNo, setSendFairNo] = useState(); // 공정번호
  const [sendDailyFairNo, setSendDailyFairNo] = useState(); // dailyFailNo
  const [sendDailyDetailNo, setSendDailyDetailNo] = useState();
  // 공사현장명 등록 Parameter 값 설정
  const [sceneName, setSceneName] = useState(); // 현장명
  const [startDate, setStarDate] = useState();
  const [endDate, setEndDate] = useState();
  const [status, setStatus] = useState('I');
  // 공사현장명 수정 Parameter 값 설정
  const [modiSceneName, setModiSceneName] = useState(); // 현장명
  const [modiStartDate, setModiStarDate] = useState();
  const [modiEndDate, setModiEndDate] = useState();
  const [modiStatus, setModiStatus] = useState();
  // 삭제상태 값
  const [deleteYn, setDeleteYn] = useState();
  // Dialog 상태 값
  const [firstDialogOpen, setFirstDialogOpen] = useState(false);
  const [secondDialogOpen, setSecondDialogOpen] = useState(false);
  const [thirdDialogOpen, setThirdDialogOpen] = useState(false);
  const [forthDialogOpen, setForthDialogOpen] = useState(false);
  const [updateFirstDialogOpen, setUpdateFirstDialogOpen] = useState(false);
  const [updateSecondDialogOpen, setUpdateSecondDialogOpen] = useState(false);
  const [updateThirdDialogOpen, setUpdateThirdDialogOpen] = useState(false);
  const [updateForthDialogOpen, setUpdateForthDialogOpen] = useState(false);
  const [firstDeleteDialogOpen, setFirstDeleteDialogOpen] = useState(false);
  const [secondDeleteDialogOpen, setSecondDeleteDialogOpen] = useState(false);
  const [thirdDeleteDialogOpen, setThirdDeleteDialogOpen] = useState(false);
  const [forthDeleteDialogOpen, setForthDeleteDialogOpen] = useState(false);
  // -----------------------------------------------------------------------------------
  // 공사일보 Cell
  const [fair, setFair] = useState(); // 공종
  const [area, setArea] = useState(); // 위치
  const [workDate, setWorkDate] = useState(); // 날짜
  const [etc, setEtc] = useState(); // 특이사항
  // 공사일보 수정 Parameter
  const [modiFair, setModiFair] = useState(); // 공종
  const [modiArea, setModiArea] = useState(); // 위치
  const [modiWorkDate, setModiWorkDate] = useState(); // 날짜
  const [modiEtc, setModiEtc] = useState(); // 특이사항
  // ----------------------------------------------------------------------------------
  // 금일작업목록 Cell
  const [dailyWorkNm, setDailyWorkNm] = useState();
  // 금일작업 목록 수정 Parameter
  const [modiDailyWorkNm, setModiDailyWorkNm] = useState();
  // -----------------------------------------------------------------------------------
  // 금일작업상세내용 Cell
  const [division, setDivision] = useState(); // 구분
  const [productName, setProductName] = useState(); // 품목/이름
  const [standard, setStandard] = useState(); // 규격
  const [ea, setEa] = useState(); // 수량
  const [price, setPrice] = useState(); // 금액
  const [vendor, setVendor] = useState(); // 업체
  const [dailyEtc, setDailyEtc] = useState(); // 비고
  // -----------------------------------------------------------------------------------
  // 금일작업상세내용 수정 Parameter
  const [modiDivision, setModiDivision] = useState(); // 구분
  const [modiProductName, setModiProductName] = useState(); // 품목/이름
  const [modiStandard, setModiStandard] = useState(); // 규격
  const [modiEa, setModiEa] = useState(); // 수량
  const [modiPrice, setModiPrice] = useState(); // 금액
  const [modiVendor, setModiVendor] = useState(); // 업체
  const [modiDailyEtc, setModiDailyEtc] = useState(); // 비고
  // ----------------------------------------------------------------------------------
  // API 호출 - 공사현장명 List
  const firstGetData = async () => {
    const apiUrl = 'https://site.yeonisoft.com/cntrwkm/getCntrwkmList';
    const sendParams = {
      // cntrwkmNo: workNo
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    axios
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        setFirstConstructionList(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setSecondConstructionList([]);
    setThirdConstructionList([]);
    setForthConstructionList([]);
    setModiFair('');
    setModiArea('');
    setModiWorkDate('');
    setModiEtc('');
  };
  // API 호출 - 공사일보 List
  const secondGetData = async (sendWorkNo) => {
    // console.log(sendWorkNo);
    const apiUrl = 'https://site.yeonisoft.com/cntrwkm/getCntrwkmDailyList';
    const sendParams = {
      cntrwkmNo: sendWorkNo
      // cntrwkmDailyNo: dailyNo
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    axios
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        console.log('공사일보');
        setSecondConstructionList(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setThirdConstructionList([]);
    setForthConstructionList([]);
    setSendDailyNo('');
    setSendFairNo('');
    setDailyWorkNm('');
    setModiDailyWorkNm('');
    setModiFair('');
    setModiArea('');
    setModiWorkDate('');
    setModiEtc('');
  };
  // 금일작업목록 API 호출
  const thirdGetData = async (sendWorkNo, sendDailyNo) => {
    const apiUrl = 'https://site.yeonisoft.com/cntrwkm/getCntrwDailyFairList';
    const sendParams = {
      cntrwkmNo: sendWorkNo,
      cntrwkmDailyNo: sendDailyNo
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    axios
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        setThirdConstructionList(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setForthConstructionList([]);
    // setSendFairNo('');
    setSendDailyDetailNo('');
    setModiDivision('');
    setModiProductName('');
    setModiStandard('');
    setModiEa('');
    setModiPrice('');
    setModiVendor('');
    setModiDailyEtc('');
  };
  // 금일작업목록 API 호출
  const forthGetData = async (sendWorkNo, sendDailyNo, sendFairNo) => {
    const apiUrl = 'https://site.yeonisoft.com/cntrwkm/getCntrwkmDailyDetailList';
    const sendParams = {
      cntrwkmNo: sendWorkNo,
      cntrwkmDailyNo: sendDailyNo,
      fairNo: sendFairNo
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    axios
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        console.log('금일작업상세내용');
        setForthConstructionList(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setSendDailyDetailNo('');
  };
  // 공사현장명 추가 API
  const firstDialogSaveApi = async () => {
    const apiUrl = 'https://site.yeonisoft.com/cntrwkm/regCntrwkm';
    const sendParams = {
      cntrwkmNm: sceneName,
      cntrwkmSDate: startDate,
      cntrwkmEDate: endDate,
      cntrwkmSttus: status
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    axios
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        console.log(response.data);
        // console.log(sceneNameResult);
        firstGetData();
      })
      .catch((error) => {
        console.log(error);
      });
    setSceneName();
    setStarDate();
    setEndDate();
    setStatus();
  };
  // 공사일보 등록 API
  const secondDialogSaveApi = async () => {
    const apiUrl = 'https://site.yeonisoft.com/cntrwkm/regCntrwkmDaily';
    const sendParams = {
      cntrwkmNo: sendWorkNo, // 공사번호
      cntrwkmKnd: fair, // 공종
      cntrwkmGnr: area, // 세대, 위치
      cntrwkmDailyDate: workDate, // 날짜
      cntrwkmDailyEtc: etc // 특이사항
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    axios
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        console.log(response.data);
        secondGetData(sendWorkNo);
      })
      .catch((error) => {
        console.log(error);
      });
    setFair();
    setArea();
    setWorkDate();
    setEtc();
  };
  // 금일작업목록 등록 API 호출
  const thirdDialogSaveApi = async () => {
    const apiUrl = 'https://site.yeonisoft.com/cntrwkm/regCntrwDailyFair';
    const sendParams = {
      cntrwkmNo: sendWorkNo, // 공사 고유번호
      cntrwkmDailyNo: sendDailyNo, // 공사일보 고유번호
      fairNo: thirdConstructionList.length + 1,
      fairNm: dailyWorkNm,
      workNm: dailyWorkNm // 작업내용
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    axios
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        console.log(response.data);
        thirdGetData(sendWorkNo, sendDailyNo);
      })
      .catch((error) => {
        console.log(error);
      });
    setDailyWorkNm();
  };
  // 금일작업상세내용 등록 API 호출
  const forthDialogSaveApi = async () => {
    const apiUrl = 'https://site.yeonisoft.com/cntrwkm/regCntrwkmDailyDetail';
    const sendParams = {
      cntrwkmNo: sendWorkNo, // 공사 고유번호
      cntrwkmDailyNo: sendDailyNo, // 공사일보 고유번호
      fairNo: sendFairNo, // 공정번호
      workKdd: division, // 구분
      workPrdlst: productName, // 품목/이름
      workStndrd: standard, // 규격
      workQy: ea, // 수량
      workAmount: price, // 금액
      workEntrps: vendor, // 업체명
      workDetail: '', // 상세내역
      workRm: dailyEtc, // 비고
      uptUser: sessionStorage.getItem('id')
    };
    console.log(sendParams);
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    axios
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        // setSecondConstructionList(response.data);
        console.log(sendParams);
        console.log(response.data);
        forthGetData(sendWorkNo, sendDailyNo, sendFairNo);
      })
      .catch((error) => {
        console.log(error);
      });
    setDivision();
    setProductName();
    setStandard();
    setEa();
    setEa();
    setPrice();
    setVendor();
    setDailyEtc();
  };
  // 공사현장명 삭제 API
  const firstDialogUpdateApi = async (deleteYn) => {
    console.log(deleteYn);
    const apiUrl = 'https://site.yeonisoft.com/cntrwkm/updCntrwkm';
    const sendParams = {
      cntrwkmNo: sendWorkNo,
      cntrwkmNm: modiSceneName,
      cntrwkmSDate: modiStartDate,
      cntrwkmEDate: modiEndDate,
      cntrwkmSttus: modiStatus,
      delYn: deleteYn
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    axios
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        console.log(response.data.code);
        setSecondConstructionList([]);
        setThirdConstructionList([]);
        setForthConstructionList([]);
        firstGetData();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // ---------------------------------------------------------------------
  // 공사일보 삭제 API
  const secondDialogUpdateApi = async (deleteYn) => {
    console.log(deleteYn);
    const apiUrl = 'https://site.yeonisoft.com/cntrwkm/updCntrwkmDaily';
    const sendParams = {
      cntrwkmNo: sendWorkNo,
      cntrwkmDailyNo: sendDailyNo,
      cntrwkmKnd: modiFair, // 공종
      cntrwkmGnr: modiArea, // 세대, 위치
      cntrwkmDailyDate: modiWorkDate, // 날짜
      cntrwkmDailyEtc: modiEtc, // 특이사항
      delYn: deleteYn
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    axios
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        console.log(response.data);
        setThirdConstructionList([]);
        setForthConstructionList([]);
        secondGetData(sendWorkNo);
      })
      .catch((error) => {
        console.log(error);
      });
    setSendDailyNo();
  };
  // ------------------------------------------------------------------------
  // 금일작업목록 삭제 API
  const thirdDialogUpdateApi = async (deleteYn) => {
    console.log(deleteYn);
    console.log(sendDailyFairNo);
    const apiUrl = 'https://site.yeonisoft.com/cntrwkm/updCntrwDailyFair';
    const sendParams = {
      cntrwkmNo: sendWorkNo,
      cntrwkmDailyNo: sendDailyNo,
      cntrwkmDailyFairNo: sendDailyFairNo,
      fairNo: sendFairNo,
      fairNm: modiDailyWorkNm,
      workNm: modiDailyWorkNm,
      delYn: deleteYn
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    axios
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        console.log(response.data);
        setForthConstructionList([]);
        thirdGetData(sendWorkNo, sendDailyNo);
      })
      .catch((error) => {
        console.log(error);
      });
    setSendDailyFairNo();
  };
  // ------------------------------------------------------------------------
  // 금일작업상세내역 삭제 API
  const forthDialogUpdateApi = async (deleteYn) => {
    console.log(deleteYn);
    const apiUrl = 'https://site.yeonisoft.com/cntrwkm/updCntrwkmDailyDetail';
    const sendParams = {
      cntrwkmNo: sendWorkNo,
      cntrwkmDailyNo: sendDailyNo,
      cntrwkmDailyDetailNo: sendDailyDetailNo,
      fairNo: sendFairNo,
      workKdd: modiDivision,
      workPrdlst: modiProductName,
      workStndrd: modiStandard,
      workQy: modiEa,
      workAmount: modiPrice,
      workEntrps: modiVendor,
      workRm: modiDailyEtc,
      delYn: deleteYn
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    axios
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        console.log(response.data);
        forthGetData(sendWorkNo, sendDailyNo, sendFairNo);
      })
      .catch((error) => {
        console.log(error);
      });
    setSendDailyDetailNo();
  };
  // -----------------------------------------------------------------------
  // First 등록 Dialog Open
  const handleFirstClickOpen = () => {
    setFirstDialogOpen(true);
  };
  // second 등록 Dialog Open
  const handleSecondClickDialogOpen = () => {
    console.log(sendWorkNo);
    if (sendWorkNo === undefined || sendWorkNo === null || sendWorkNo === '') {
      window.alert('추가하실 공사현장을 선택해주세요');
      return;
    }
    setSecondDialogOpen(true);
  };
  // third 등록 Dialog Open
  const handleThirdClickDialogOpen = () => {
    console.log(sendDailyNo);
    console.log(thirdConstructionList.length);
    if (sendDailyNo === undefined || sendDailyNo === null || sendDailyNo === '') {
      window.alert('추가하실 공사일보을 선택해주세요');
      return;
    }
    setThirdDialogOpen(true);
  };
  // forth 등록 Dialog Open
  const handleForthClickDialogOpen = () => {
    if (sendFairNo === undefined || sendFairNo === null || sendFairNo === '') {
      window.alert('추가하실 금일작업목록을 선택해주세요');
      return;
    }
    setForthDialogOpen(true);
  };
  // -------------------------------------------------------------------------
  // 삭제 Dialog Open
  // -------------------------------------------------------------------------
  // First Delete Dialog Open
  const handleFirstDeleteDialogOpen = () => {
    if (sendWorkNo === undefined || sendWorkNo === null) {
      window.alert('삭제하실 데이터를 선택해주세요');
      return;
    }
    if (firstConstructionList.length <= 0) {
      window.alert('삭제하실 데이터가 없습니다');
      return;
    }
    setFirstDeleteDialogOpen(true);
  };
  // Second Delete Dialog Open
  const handleSecondDeleteDialogOpen = () => {
    if (sendDailyNo === undefined || sendDailyNo === null) {
      window.alert('삭제하실 데이터를 선택해주세요');
      return;
    }
    if (secondConstructionList.length <= 0) {
      window.alert('삭제하실 데이터가 없습니다');
      return;
    }
    setSecondDeleteDialogOpen(true);
  };
  // Third Delete Dialog Open
  const handleThirdDeleteDialogOpen = () => {
    if (sendDailyFairNo === undefined || sendDailyFairNo === null) {
      window.alert('삭제하실 데이터를 선택해주세요');
      return;
    }
    if (thirdConstructionList.length <= 0) {
      window.alert('삭제하실 데이터가 없습니다');
      return;
    }
    setThirdDeleteDialogOpen(true);
  };
  // Forth Delete Dialog Open
  const handleForthDeleteDialogOpen = () => {
    if (sendDailyDetailNo === undefined || sendDailyDetailNo === null) {
      window.alert('삭제하실 데이터를 선택해주세요');
      return;
    }
    if (forthConstructionList.length <= 0) {
      window.alert('삭제하실 데이터가 없습니다');
      return;
    }
    setForthDeleteDialogOpen(true);
  };
  // Update Dialog Open
  // First Update Dialog Open
  const handleUpdateFirstDialogOpen = () => {
    if (modiSceneName === undefined || modiSceneName === null || modiSceneName === '') {
      window.alert('수정할 데이터를 선택해주세요');
      return;
    }
    setUpdateFirstDialogOpen(true);
  };
  // Second Update Dialog Open
  const handleUpdateSecondDialogOpen = () => {
    if (sendDailyNo === undefined || sendDailyNo === null || sendDailyNo === '') {
      window.alert('수정할 데이터를 선택해주세요');
      return;
    }
    if (secondConstructionList.length <= 0) {
      window.alert('삭제하실 데이터가 없습니다');
      // 데이터 없을시 초기화
      setModiFair();
      setModiArea();
      setModiWorkDate();
      setModiEtc();
      return;
    }
    setUpdateSecondDialogOpen(true);
  };
  // Third Update Dialog Open
  const handleUpdateThirdDialogOpen = () => {
    if (modiDailyWorkNm === undefined || modiDailyWorkNm === null) {
      window.alert('수정할 데이터를 선택해주세요');
      return;
    }
    if (sendDailyNo === undefined || sendDailyNo === null || sendDailyNo === '') {
      window.alert('수정할 데이터를 선택해주세요');
      return;
    }
    if (sendFairNo === undefined || sendFairNo === null || sendFairNo === '') {
      window.alert('수정할 데이터를 선택해주세요');
      return;
    }
    if (thirdConstructionList >= 0) {
      setModiDailyWorkNm();
    }
    // setModiDailyWorkNm('');
    setUpdateThirdDialogOpen(true);
  };
  // Forth Update Dialog Open
  const handleUpdateForthDialogOpen = () => {
    // sendDailyDetailNo
    if (sendDailyDetailNo === undefined || sendDailyDetailNo === null || sendDailyDetailNo === '') {
      window.alert('수정할 데이터를 선택해주세요');
      return;
    }
    if (sendDailyNo === undefined || sendDailyNo === null || sendDailyNo === '') {
      window.alert('수정할 데이터를 선택해주세요');
      return;
    }
    if (forthConstructionList <= 0) {
      window.alert('수정할 데이터를 선택해주세요');
      setModiDivision('');
      setModiProductName('');
      setModiStandard('');
      setModiEa('');
      setModiPrice('');
      setModiVendor('');
      setModiDailyEtc('');
      return;
    }
    setUpdateForthDialogOpen(true);
  };
  // ------------------------------------------------------------------------
  // First Dialog Close
  const handleFristClickDialogClose = () => {
    setSceneName('');
    setStarDate('');
    setEndDate('');
    setStatus('');
    setFirstDialogOpen(false);
    setUpdateFirstDialogOpen(false);
  };
  // Second Dialog Close
  const handleSecondClickDialogClose = () => {
    setFair('');
    setArea('');
    setWorkDate('');
    setEtc('');
    setSecondDialogOpen(false);
    setUpdateSecondDialogOpen(false);
  };
  // Third Dialog Close
  const handleThirdClickDialogClose = () => {
    setDailyWorkNm('');
    setThirdDialogOpen(false);
    setUpdateThirdDialogOpen(false);
  };
  // Forth Dialog Close
  const handleForthClickDialogClose = () => {
    setDivision(''); // 구분
    setProductName(''); // 품목/이름
    setStandard(''); // 규격
    setEa(''); // 수량
    setVendor(''); // 업체
    setPrice(''); // 금액
    setDailyEtc(''); // 비고
    setForthDialogOpen(false);
    setUpdateForthDialogOpen(false);
  };
  // 첫번째 삭제 Dialog Close
  const handleFirstDeleteDialogClose = () => {
    setFirstDeleteDialogOpen(false);
  };
  // 두번째 삭제 Dialog Close
  const handleSecondDeleteDialogClose = () => {
    setSecondDeleteDialogOpen(false);
  };
  // 세번째 삭제 Dialog Close
  const handleThirdDeleteDialogClose = () => {
    setThirdDeleteDialogOpen(false);
  };
  // 네번째 삭제 Dialog Close
  const handleForthDeleteDialogClose = () => {
    setForthDeleteDialogOpen(false);
  };
  // ----------------------------------------------------------------------
  // popup 내용 저장 --------------------------------------------------------
  const handleClickFirstDialogSave = () => {
    // setSceneName();
    console.log(`SceneName : ${sceneName}`);
    firstDialogSaveApi(); // API 호출
    console.log('공사현장명 API 호출');
    handleFristClickDialogClose();
  };
  const handleClickSecondDialogSave = () => {
    console.log(`sendWorkNo : ${sendWorkNo}`);
    console.log(`sendDailyNo : ${sendDailyNo}`);
    console.log('공사일보 API 호출');
    secondDialogSaveApi(); // API 호출
    handleSecondClickDialogClose();
  };
  const handleClickThirdDialogSave = () => {
    console.log(`sendWorkNo : ${sendWorkNo}`);
    console.log(`sendDailyNo : ${sendDailyNo}`);
    console.log('금일작업목록 API 호출');
    thirdDialogSaveApi(); // API 호출
    handleThirdClickDialogClose();
  };
  const handleClickForthDialogSave = () => {
    console.log(`sendWorkNo : ${sendWorkNo}`);
    console.log(`sendDailyNo : ${sendDailyNo}`);
    console.log(`sendFairNo : ${sendFairNo}`);
    console.log('금일작업상세내용 API 호출');
    forthDialogSaveApi(); // API 호출
    handleForthClickDialogClose();
  };
  // ------------------------------------------------------------------------
  // 삭제 API 호출
  const handleFirstDeleteDialog = () => {
    firstDialogUpdateApi('Y');
    handleFirstDeleteDialogClose();
  };
  const handleSecondDeleteDialog = () => {
    secondDialogUpdateApi('Y');
    handleSecondDeleteDialogClose();
  };
  const handleThirdDeleteDialog = () => {
    thirdDialogUpdateApi('Y');
    handleThirdDeleteDialogClose();
  };
  const handleForthDeleteDialog = () => {
    forthDialogUpdateApi('Y');
    handleForthDeleteDialogClose();
  };
  // ----------------------------------------------------------------------------
  // 공사현장명 수정 Dialog
  const handleFirstUpdateDialog = () => {
    firstDialogUpdateApi('N');
    setUpdateFirstDialogOpen(false);
    handleFirstDeleteDialogClose();
  };
  // 공사일보 수정 Dialog
  const handleSecondUpdateDialog = () => {
    secondDialogUpdateApi('N');
    setUpdateSecondDialogOpen(false);
    handleSecondDeleteDialogClose();
  };
  // 금일작업목록 수정 Dialog
  const handleThirdUpdateDialog = () => {
    thirdDialogUpdateApi('N');
    setUpdateThirdDialogOpen(false);
    handleThirdDeleteDialogClose();
  };
  // 금일작업상세내용 수정 Dialog
  const handleForthUpdateDialog = () => {
    forthDialogUpdateApi('N');
    setUpdateForthDialogOpen(false);
    handleForthDeleteDialogClose();
  };
  // ------------------------------------------------------------------------
  // 공사현장 Parameter Save
  const onChangeScenename = (e) => {
    setSceneName(e.target.value || '');
  };
  const onChangeStartDate = (e) => {
    setStarDate(e.target.value || '');
  };
  const onChangeEndDate = (e) => {
    setEndDate(e.target.value || '');
  };
  const onChangeStatus = (e) => {
    setStatus(e.target.value || '');
  };
  // -----------------------------------------------------------------------
  const onChangeModiScenename = (e) => {
    setModiSceneName(e.target.value || '');
  };
  const onChangeModiStartDate = (e) => {
    setModiStarDate(e.target.value || '');
  };
  const onChangeModiEndDate = (e) => {
    setModiEndDate(e.target.value || '');
  };
  const onChangeModiStatus = (e) => {
    setModiStatus(e.target.value || '');
  };
  // ----------------------------------------------------------------------
  // Grid Cell Click Event
  const onFirstCellClick = (params) => {
    setSecondConstructionList([]);
    setThirdConstructionList([]);
    setForthConstructionList([]);
    secondGetData(params.row.cntrwkmNo);
    setSendWokrNo(params.row.cntrwkmNo);
    setModiSceneName(params.row.cntrwkmNm);
    setModiStarDate(params.row.cntrwkmSDate);
    setModiEndDate(params.row.cntrwkmEDate);
    setModiStatus(params.row.cntrwkmSttus);
  };
  const onSecondCellClick = (params) => {
    setThirdConstructionList([]);
    setForthConstructionList([]);
    thirdGetData(params.row.cntrwkmNo, params.row.cntrwkmDailyNo);
    console.log(params.row.cntrwkmNo, params.row.cntrwkmDailyNo);
    setSendWokrNo(params.row.cntrwkmNo);
    setSendDailyNo(params.row.cntrwkmDailyNo);
    setModiFair(params.row.cntrwkmKnd);
    setModiArea(params.row.cntrwkmGnr);
    setModiWorkDate(params.row.cntrwkmDailyDate);
    setModiEtc(params.row.cntrwkmDailyEtc);
  };
  const onThirdCellClick = (params) => {
    setForthConstructionList([]);
    forthGetData(params.row.cntrwkmNo, params.row.cntrwkmDailyNo, params.row.fairNo);
    console.log(params.row.cntrwkmDailyFairNo);
    setSendWokrNo(params.row.cntrwkmNo);
    setSendDailyNo(params.row.cntrwkmDailyNo);
    setSendFairNo(params.row.fairNo);
    setSendDailyFairNo(params.row.cntrwkmDailyFairNo);
    setModiDailyWorkNm(params.row.workNm);
  };
  const onForthCellClick = (params) => {
    console.log(params.row.cntrwkmDailyDetailNo);
    setSendWokrNo(params.row.cntrwkmNo);
    setSendDailyNo(params.row.cntrwkmDailyNo);
    setSendFairNo(params.row.fairNo);
    setSendDailyDetailNo(params.row.cntrwkmDailyDetailNo);
    setModiDivision(params.row.workKdd);
    setModiProductName(params.row.workPrdlst);
    setModiStandard(params.row.workStndrd);
    setModiEa(params.row.workQy);
    setModiPrice(params.row.workAmount);
    setModiVendor(params.row.workEntrps);
    setModiDailyEtc(params.row.workRm);
  };
  // ---------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------
  // 공사일보 Parameter Save
  const onChangeFair = (e) => {
    setFair(e.target.value || '');
  };
  const onChangeArea = (e) => {
    setArea(e.target.value || '');
  };
  const onChangeWorkDate = (e) => {
    setWorkDate(e.target.value || '');
  };
  const onChangeEtc = (e) => {
    setEtc(e.target.value || '');
  };
  // ----------------------------------------------------------------------
  const onChangeModiFair = (e) => {
    setModiFair(e.target.value || '');
  };
  const onChangeModiArea = (e) => {
    setModiArea(e.target.value || '');
  };
  const onChangeModiWorkDate = (e) => {
    setModiWorkDate(e.target.value || '');
  };
  const onChangeModiEtc = (e) => {
    setModiEtc(e.target.value || '');
  };
  // -----------------------------------------------------------------------
  // 금일작업목록 Parameter Save
  const onChangeDailyWorkNm = (e) => {
    setDailyWorkNm(e.target.value || '');
  };
  // -----------------------------------------------------------------------
  const onChangeModiDailyWorkNm = (e) => {
    setModiDailyWorkNm(e.target.value || '');
  };
  // -------------------------------------------------------------------------
  // 금일작업 상세내용 Parameter Save
  const onChangeDivision = (e) => {
    // 구분
    setDivision(e.target.value || '');
  };
  const onChangeStandard = (e) => {
    // 규격
    setStandard(e.target.value || '');
  };
  const onChangeProductNm = (e) => {
    // 품목/이름
    setProductName(e.target.value || '');
  };
  const onChangeEa = (e) => {
    // 수량
    setEa(parseInt(e.target.value, 10));
  };
  const onChangePrice = (e) => {
    // 금액
    setPrice(parseInt(e.target.value, 10));
  };
  const onChangeVendor = (e) => {
    // 업체
    setVendor(e.target.value || '');
  };
  const onChangeDailyEtc = (e) => {
    // 비고
    setDailyEtc(e.target.value || '');
  };
  // -------------------------------------------------------------------------
  const onChangeModiDivision = (e) => {
    // 구분
    setModiDivision(e.target.value || '');
  };
  const onChangeModiStandard = (e) => {
    // 규격
    setModiStandard(e.target.value || '');
  };
  const onChangeModiProductNm = (e) => {
    // 품목/이름
    setModiProductName(e.target.value || '');
  };
  const onChangeModiEa = (e) => {
    // 수량
    setModiEa(parseInt(e.target.value, 10));
  };
  const onChangeModiPrice = (e) => {
    // 금액
    setModiPrice(parseInt(e.target.value, 10));
  };
  const onChangeModiVendor = (e) => {
    // 업체
    setModiVendor(e.target.value || '');
  };
  const onChangeModiDailyEtc = (e) => {
    // 비고
    setModiDailyEtc(e.target.value || '');
  };
  // -------------------------------------------------------------------------
  // useEffect
  useEffect(() => {
    firstGetData();
  }, []);
  useEffect(() => {
    // secondGetData();
  }, []);
  // ----------------------------------------------------------------------
  return (
    <Page>
      {/* <Stack justifyContent="flex-end">
        <AccountPopover />
      </Stack> */}
      <RootStyle title="공사관리등록">
        <AuthLayout>
          <Link underline="none" variant="subtitle2" component={RouterLink} to="/">
            홈으로
          </Link>
        </AuthLayout>
        <Grid container spacing={0.5}>
          <Grid item xs={6} md={4}>
            <Container spacing={1}>
              <ContentStyle>
                <Stack direction="row" justifyContent="space-between">
                  <Stack>
                    <Typography variant="h5" gutterBottom align="left">
                      공사 현장명
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
                    <Button variant="outlined" onClick={handleFirstClickOpen}>
                      추가
                    </Button>
                    <Dialog
                      open={firstDialogOpen}
                      onClose={handleFristClickDialogClose}
                      PaperProps={{ sx: { width: '30%', height: '55%' } }}
                    >
                      <DialogTitle align="center">공사현장 추가</DialogTitle>
                      <DialogContent spacing={2}>
                        <Stack direction="column" justifyContent="space-between" spacing={3}>
                          <TextField
                            label="현장명"
                            type="text"
                            variant="standard"
                            value={sceneName || ''}
                            onChange={onChangeScenename}
                          />
                          <TextField
                            type="date"
                            variant="standard"
                            value={startDate || ''}
                            onChange={onChangeStartDate}
                          />
                          <TextField
                            type="date"
                            variant="standard"
                            value={endDate || ''}
                            onChange={onChangeEndDate}
                          />
                          <FormControl>
                            <FormLabel>상태</FormLabel>
                            <RadioGroup>
                              <FormControlLabel
                                checked={status === 'I'}
                                onChange={onChangeStatus}
                                value={status}
                                control={<Radio />}
                                label="공사중"
                              />
                              <FormControlLabel
                                checked={status !== 'I'}
                                onChange={onChangeStatus}
                                value={status}
                                control={<Radio />}
                                label="마감"
                              />
                            </RadioGroup>
                          </FormControl>
                          {/* <TextField Label="비고" type="text" name="etc"value={sceneName} /> */}
                        </Stack>
                        <Stack direction="row" justifyContent="center" spacing={1}>
                          <Button variant="contained" onClick={handleClickFirstDialogSave}>
                            저장
                          </Button>
                          <Button variant="contained" onClick={handleFristClickDialogClose}>
                            취소
                          </Button>
                        </Stack>
                      </DialogContent>
                    </Dialog>
                    <Button variant="outlined" onClick={handleUpdateFirstDialogOpen}>
                      수정
                    </Button>
                    <Dialog
                      open={updateFirstDialogOpen}
                      onClose={handleFristClickDialogClose}
                      PaperProps={{ sx: { width: '30%', height: '55%' } }}
                    >
                      <DialogTitle align="center">공사현장 수정</DialogTitle>
                      <DialogContent spacing={2}>
                        <Stack direction="column" justifyContent="space-between" spacing={3}>
                          <TextField
                            label="현장명"
                            type="text"
                            variant="standard"
                            value={modiSceneName}
                            onChange={onChangeModiScenename}
                          />
                          <TextField
                            type="date"
                            variant="standard"
                            value={modiStartDate}
                            onChange={onChangeModiStartDate}
                          />
                          <TextField
                            type="date"
                            variant="standard"
                            value={modiEndDate}
                            onChange={onChangeModiEndDate}
                          />
                          <FormControl>
                            <FormLabel>상태</FormLabel>
                            <RadioGroup>
                              <FormControlLabel
                                checked={modiStatus === 'I'}
                                onChange={onChangeModiStatus}
                                value={modiStatus}
                                control={<Radio />}
                                label="공사중"
                              />
                              <FormControlLabel
                                checked={modiStatus !== 'I'}
                                onChange={onChangeModiStatus}
                                value={modiStatus}
                                control={<Radio />}
                                label="마감"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Stack>
                        <Stack direction="row" justifyContent="center" spacing={1}>
                          <Button variant="contained" onClick={handleFirstUpdateDialog}>
                            저장
                          </Button>
                          <Button variant="contained" onClick={handleFristClickDialogClose}>
                            취소
                          </Button>
                        </Stack>
                      </DialogContent>
                    </Dialog>
                    <Button variant="contained" onClick={handleFirstDeleteDialogOpen}>
                      삭제
                    </Button>
                    <Dialog
                      open={firstDeleteDialogOpen}
                      onClose={handleFirstDeleteDialogClose}
                      PaperProps={{ sx: { width: '30%', height: '17%' } }}
                    >
                      <DialogTitle align="center">선택항목을 삭제 하시겠습니까?</DialogTitle>
                      <DialogContent>
                        <Stack direction="row" justifyContent="center" spacing={1}>
                          <Button variant="contained" onClick={handleFirstDeleteDialog}>
                            삭제
                          </Button>
                          <Button variant="contained" onClick={handleFirstDeleteDialogClose}>
                            취소
                          </Button>
                        </Stack>
                      </DialogContent>
                    </Dialog>
                  </Stack>
                </Stack>
                <DataGrid
                  // checkboxSelection
                  rows={firstConstructionList}
                  columns={FIRST_TABLE_HEAD}
                  onCellClick={onFirstCellClick}
                  hideFooterPagination
                  disableColumnMenu
                />
              </ContentStyle>
            </Container>
          </Grid>
          <Grid item xs={6} md={8}>
            <Container>
              <ContentStyle2>
                <Stack direction="row" justifyContent="space-between">
                  <Stack>
                    <Typography variant="h5" gutterBottom align="center">
                      공사일보
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
                    <Button variant="outlined" onClick={handleSecondClickDialogOpen}>
                      추가
                    </Button>
                    <Dialog
                      open={secondDialogOpen}
                      onClose={handleSecondClickDialogClose}
                      PaperProps={{ sx: { width: '30%', height: '60%' } }}
                    >
                      <DialogTitle align="center">공사일보 등록</DialogTitle>
                      <DialogContent>
                        <Stack direction="column" justifyContent="space-between" spacing={2}>
                          <TextField
                            label="공종"
                            type="text"
                            variant="standard"
                            value={fair || ''}
                            onChange={onChangeFair}
                          />
                          <TextField
                            label="위치"
                            type="text"
                            variant="standard"
                            value={area || ''}
                            onChange={onChangeArea}
                          />
                          <br />
                          <TextField
                            type="date"
                            variant="standard"
                            value={workDate || ''}
                            onChange={onChangeWorkDate}
                          />
                          <TextField
                            label="특이사항"
                            type="text"
                            variant="standard"
                            value={etc || ''}
                            onChange={onChangeEtc}
                          />
                          <br />
                        </Stack>
                        <Stack direction="row" justifyContent="center" spacing={2}>
                          <Button variant="contained" onClick={handleClickSecondDialogSave}>
                            저장
                          </Button>
                          <Button variant="contained" onClick={handleSecondClickDialogClose}>
                            취소
                          </Button>
                        </Stack>
                      </DialogContent>
                    </Dialog>
                    <Button variant="outlined" onClick={handleUpdateSecondDialogOpen}>
                      수정
                    </Button>
                    <Dialog
                      open={updateSecondDialogOpen}
                      onClose={handleSecondClickDialogClose}
                      PaperProps={{ sx: { width: '30%', height: '60%' } }}
                    >
                      <DialogTitle align="center">공사일보 수정</DialogTitle>
                      <DialogContent>
                        <Stack direction="column" justifyContent="space-between" spacing={2}>
                          <TextField
                            label="공종"
                            type="text"
                            variant="standard"
                            value={modiFair}
                            onChange={onChangeModiFair}
                          />
                          <TextField
                            label="위치"
                            type="text"
                            variant="standard"
                            value={modiArea}
                            onChange={onChangeModiArea}
                          />
                          <br />
                          <TextField
                            type="date"
                            variant="standard"
                            value={modiWorkDate}
                            onChange={onChangeModiWorkDate}
                          />
                          <TextField
                            label="특이사항"
                            type="text"
                            variant="standard"
                            value={modiEtc}
                            onChange={onChangeModiEtc}
                          />
                          <br />
                        </Stack>
                        <Stack direction="row" justifyContent="center" spacing={2}>
                          <Button variant="contained" onClick={handleSecondUpdateDialog}>
                            저장
                          </Button>
                          <Button variant="contained" onClick={handleSecondClickDialogClose}>
                            취소
                          </Button>
                        </Stack>
                      </DialogContent>
                    </Dialog>
                    <Button variant="contained" onClick={handleSecondDeleteDialogOpen}>
                      삭제
                    </Button>
                    <Dialog
                      open={secondDeleteDialogOpen}
                      onClose={handleSecondDeleteDialogClose}
                      PaperProps={{ sx: { width: '30%', height: '17%' } }}
                    >
                      <DialogTitle align="center">선택항목을 삭제 하시겠습니까?</DialogTitle>
                      <DialogContent>
                        <Stack direction="row" justifyContent="center" spacing={1}>
                          <Button variant="contained" onClick={handleSecondDeleteDialog}>
                            삭제
                          </Button>
                          <Button variant="contained" onClick={handleSecondDeleteDialogClose}>
                            취소
                          </Button>
                        </Stack>
                      </DialogContent>
                    </Dialog>
                  </Stack>
                </Stack>
                <div style={{ height: 300, width: '100%' }}>
                  <DataGrid
                    rows={secondConstructionList}
                    columns={SECOND_TABLE_HEAD}
                    onCellClick={onSecondCellClick}
                    hideFooterPagination
                    disableColumnMenu
                  />
                </div>
                <Stack direction="row" justifyContent="space-between">
                  <Stack>
                    <Typography variant="h6" gutterBottom align="center">
                      금일작업목록
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
                    <Button variant="outlined" onClick={handleThirdClickDialogOpen}>
                      추가
                    </Button>
                    <Dialog
                      open={thirdDialogOpen}
                      onClose={handleThirdClickDialogClose}
                      PaperProps={{ sx: { width: '30%', height: '25%' } }}
                    >
                      <DialogTitle align="center">금일작업 등록</DialogTitle>
                      <DialogContent>
                        <Stack direction="column" justifyContent="space-between" spacing={2}>
                          <TextField
                            label="작업내용"
                            type="text"
                            variant="standard"
                            value={dailyWorkNm || ''}
                            onChange={onChangeDailyWorkNm}
                          />
                          <br />
                        </Stack>
                        <Stack direction="row" justifyContent="center" spacing={2}>
                          <Button variant="contained" onClick={handleClickThirdDialogSave}>
                            저장
                          </Button>
                          <Button variant="contained" onClick={handleThirdClickDialogClose}>
                            취소
                          </Button>
                        </Stack>
                      </DialogContent>
                    </Dialog>
                    <Button variant="outlined" onClick={handleUpdateThirdDialogOpen}>
                      수정
                    </Button>
                    <Dialog
                      open={updateThirdDialogOpen}
                      onClose={handleThirdClickDialogClose}
                      PaperProps={{ sx: { width: '30%', height: '25%' } }}
                    >
                      <DialogTitle align="center">금일작업 수정</DialogTitle>
                      <DialogContent>
                        <Stack direction="column" justifyContent="space-between" spacing={2}>
                          <TextField
                            label="작업내용"
                            type="text"
                            variant="standard"
                            value={modiDailyWorkNm}
                            onChange={onChangeModiDailyWorkNm}
                          />
                          <br />
                        </Stack>
                        <Stack direction="row" justifyContent="center" spacing={2}>
                          <Button variant="contained" onClick={handleThirdUpdateDialog}>
                            저장
                          </Button>
                          <Button variant="contained" onClick={handleThirdClickDialogClose}>
                            취소
                          </Button>
                        </Stack>
                      </DialogContent>
                    </Dialog>
                    <Button variant="contained" onClick={handleThirdDeleteDialogOpen}>
                      삭제
                    </Button>
                    <Dialog
                      open={thirdDeleteDialogOpen}
                      onClose={handleThirdDeleteDialogClose}
                      PaperProps={{ sx: { width: '30%', height: '17%' } }}
                    >
                      <DialogTitle align="center">선택항목을 삭제 하시겠습니까?</DialogTitle>
                      <DialogContent>
                        <Stack direction="row" justifyContent="center" spacing={1}>
                          <Button variant="contained" onClick={handleThirdDeleteDialog}>
                            삭제
                          </Button>
                          <Button variant="contained" onClick={handleThirdDeleteDialogClose}>
                            취소
                          </Button>
                        </Stack>
                      </DialogContent>
                    </Dialog>
                  </Stack>
                  <Typography variant="h6" gutterBottom align="center">
                    금일작업상세내용
                  </Typography>
                  <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
                    <Button variant="outlined" onClick={handleForthClickDialogOpen}>
                      추가
                    </Button>
                    <Dialog
                      open={forthDialogOpen}
                      onClose={handleForthClickDialogClose}
                      PaperProps={{ sx: { width: '50%', height: '85%' } }}
                    >
                      <DialogTitle align="center">금일작업상세내용등록</DialogTitle>
                      <DialogContent>
                        <Stack direction="column" justifyContent="space-between" spacing={3}>
                          <TextField
                            label="구분"
                            type="text"
                            variant="standard"
                            value={division || ''}
                            onChange={onChangeDivision}
                          />
                          <TextField
                            label="품목/이름"
                            type="text"
                            variant="standard"
                            value={productName || ''}
                            onChange={onChangeProductNm}
                          />
                          <TextField
                            label="규격"
                            type="text"
                            variant="standard"
                            value={standard || ''}
                            onChange={onChangeStandard}
                          />
                          <TextField
                            label="수량"
                            type="number"
                            variant="standard"
                            value={ea || ''}
                            onChange={onChangeEa}
                          />
                          <TextField
                            label="금액"
                            type="number"
                            variant="standard"
                            value={price || ''}
                            onChange={onChangePrice}
                          />
                          <TextField
                            label="업체명"
                            type="text"
                            variant="standard"
                            value={vendor || ''}
                            onChange={onChangeVendor}
                          />
                          <TextField
                            label="비고"
                            type="text"
                            variant="standard"
                            value={dailyEtc || ''}
                            onChange={onChangeDailyEtc}
                          />
                          <br />
                        </Stack>
                        <Stack direction="row" justifyContent="center" spacing={1}>
                          <Button variant="contained" onClick={handleClickForthDialogSave}>
                            저장
                          </Button>
                          <Button variant="contained" onClick={handleForthClickDialogClose}>
                            취소
                          </Button>
                        </Stack>
                      </DialogContent>
                    </Dialog>
                    <Button variant="outlined" onClick={handleUpdateForthDialogOpen}>
                      수정
                    </Button>
                    <Dialog
                      open={updateForthDialogOpen}
                      onClose={handleForthClickDialogClose}
                      PaperProps={{ sx: { width: '50%', height: '85%' } }}
                    >
                      <DialogTitle align="center">금일작업상세내용수정</DialogTitle>
                      <DialogContent>
                        <Stack direction="column" justifyContent="space-between" spacing={3}>
                          <TextField
                            label="구분"
                            type="text"
                            variant="standard"
                            value={modiDivision}
                            onChange={onChangeModiDivision}
                          />
                          <TextField
                            label="품목/이름"
                            type="text"
                            variant="standard"
                            value={modiProductName}
                            onChange={onChangeModiProductNm}
                          />
                          <TextField
                            label="규격"
                            type="text"
                            variant="standard"
                            value={modiStandard}
                            onChange={onChangeModiStandard}
                          />
                          <TextField
                            label="수량"
                            type="number"
                            variant="standard"
                            value={modiEa}
                            onChange={onChangeModiEa}
                          />
                          <TextField
                            label="금액"
                            type="number"
                            variant="standard"
                            value={modiPrice}
                            onChange={onChangeModiPrice}
                          />
                          <TextField
                            label="업체명"
                            type="text"
                            variant="standard"
                            value={modiVendor}
                            onChange={onChangeVendor}
                          />
                          <TextField
                            label="비고"
                            type="text"
                            variant="standard"
                            value={modiDailyEtc}
                            onChange={onChangeModiDailyEtc}
                          />
                          <br />
                        </Stack>
                        <Stack direction="row" justifyContent="center" spacing={1}>
                          <Button variant="contained" onClick={handleForthUpdateDialog}>
                            저장
                          </Button>
                          <Button variant="contained" onClick={handleForthClickDialogClose}>
                            취소
                          </Button>
                        </Stack>
                      </DialogContent>
                    </Dialog>
                    <Button variant="contained" onClick={handleForthDeleteDialogOpen}>
                      삭제
                    </Button>
                    <Dialog
                      open={forthDeleteDialogOpen}
                      onClose={handleForthDeleteDialogClose}
                      PaperProps={{ sx: { width: '30%', height: '17%' } }}
                    >
                      <DialogTitle align="center">선택항목을 삭제 하시겠습니까?</DialogTitle>
                      <DialogContent>
                        <Stack direction="row" justifyContent="center" spacing={1}>
                          <Button variant="contained" onClick={handleForthDeleteDialog}>
                            삭제
                          </Button>
                          <Button variant="contained" onClick={handleForthDeleteDialogClose}>
                            취소
                          </Button>
                        </Stack>
                      </DialogContent>
                    </Dialog>
                  </Stack>
                </Stack>
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <div style={{ height: 250, width: '25%' }}>
                    <DataGrid
                      rows={thirdConstructionList}
                      columns={THIRD_TABLE_HEAD}
                      onCellClick={onThirdCellClick}
                      hideFooterPagination
                      disableColumnMenu
                    />
                  </div>
                  <div style={{ height: 250, width: '75%' }}>
                    <DataGrid
                      rows={forthConstructionList}
                      columns={FORTH_TABLE_HEAD}
                      onCellClick={onForthCellClick}
                      hideFooterPagination
                      disableColumnMenu
                    />
                  </div>
                </Stack>
              </ContentStyle2>
            </Container>
          </Grid>
        </Grid>
      </RootStyle>
    </Page>
  );
}
