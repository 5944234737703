import * as Yup from 'yup';
// import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
// import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function FindIdForm() {
  // const navigate = useNavigate();
  // const [showPassword, setShowPassword] = useState(false);

  const CheckSchema = Yup.object().shape({
    id: Yup.string()
      .min(2, '아이디를 2자이상으로 입력해주세요')
      .max(50, '아이디를 50자이하로 입력해주세요')
      .required('아이디를 입력해주세요'),
    username: Yup.string().required('이름을 입력해주세요'),
    email: Yup.string().email('Email 주소가 아닙니다').required('Email을 입력해주세요'),
    phone1: Yup.string().required('전화번호를 입력해주세요'),
    phone2: Yup.string().required('전화번호를 입력해주세요'),
    phone3: Yup.string().required('전화번호를 입력해주세요')
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      email: '',
      phone1: '',
      phone2: '',
      phone3: ''
    },
    validationSchema: CheckSchema,
    onSubmit: () => {
      // navigate('/dashboard', { replace: true });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={1}>
          <TextField
            fullWidth
            autoComplete="username"
            type="name"
            label="이름"
            {...getFieldProps('username')}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
          />
          <TextField
            fullWidth
            autoComplete="email"
            type="email"
            label="이메일"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={0.5}>
            <Typography align="center" variant="h6">
              폰
            </Typography>
            <TextField
              fullWidth
              autoComplete="phone"
              type="phone"
              label=""
              {...getFieldProps('phone1')}
              error={Boolean(touched.phone1 && errors.phone1)}
              helperText={touched.phone1 && errors.phone1}
            />
            <Typography align="center" variant="h4" gutterBottom>
              -
            </Typography>
            <TextField
              fullWidth
              autoComplete="phone"
              type="phone"
              label=""
              {...getFieldProps('phone2')}
              error={Boolean(touched.phone2 && errors.phone2)}
              helperText={touched.phone2 && errors.phone2}
            />
            <Typography align="center" variant="h4" gutterBottom>
              -
            </Typography>
            <TextField
              fullWidth
              autoComplete="phone"
              type="phone"
              label=""
              {...getFieldProps('phone3')}
              error={Boolean(touched.phone3 && errors.phone3)}
              helperText={touched.phone3 && errors.phone3}
            />
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            아이디 찾기
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
