/* eslint-disable prettier/prettier */
import { Navigate, useRoutes } from 'react-router-dom';
import { useState } from 'react';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import CompanyRegister from './pages/CompanyRegister';
import DashboardApp from './pages/DashboardApp';
import Construction from './pages/Construction';
// import ConstructionTable from './pages/ConstructionTable';
import RecruitmentNotice from './pages/RecruitmentNotice';
import ConstructorRegistration from './pages/ConstructorRegistration';
import ConstructorRegistrationTable from './pages/ConstructorRegistrationTable';
import RecuitmentNoticeRegistration from './pages/RecuitmentNoticeRegistration';
// import DataGrid from './pages/DataGrid';
// import Blog from './pages/Blog';
import User from './pages/User';
import NotFound from './pages/Page404';
import FindIdPassword from './pages/FindIdPassword';
import ConstructionDetail from './sections/construction/ConstructionDetail';

// ----------------------------------------------------------------------

export default function Router() {  
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'Construction', element: <Construction /> },
        { path: 'RecruitmentNotice', element: <RecruitmentNotice /> },
        { path: 'ConstructorRegistration', element: <ConstructorRegistration /> }
        // { path: 'blog', element: <Blog /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'companyRegister', element: <CompanyRegister /> },
        { path: 'findIdPassword', element: <FindIdPassword /> },        
        { path: 'ConstructorRegistrationTable', element: <ConstructorRegistrationTable /> },
        { path: 'ConstructorRegistration', element: <ConstructorRegistration /> },
        // { path: 'ConstructionDetail', element: <ConstructionDetail cntrwkmNo={cntrwkmNo} cntrwkmDailyNo={cntrwkmDailyNo} fairNo={fairNo}/> },
        { path: 'RecuitmentNoticeRegistration', element: <RecuitmentNoticeRegistration /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replaceto='/dashboard'/> }
  ]);
}
