import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@mui/material';
import axios from 'axios';
// components
import Iconify from '../../components/Iconify';
import MenuPopover from '../../components/MenuPopover';
//
import account from '../../_mocks_/account';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: '홈화면',
    icon: 'eva:home-fill',
    linkTo: '/dashboard/app'
  },
  {
    label: '개인정보',
    icon: 'eva:person-fill',
    linkTo: '#'
  },
  {
    label: '설정',
    icon: 'eva:settings-2-fill',
    linkTo: '#'
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const getData = async () => {
    const sendParams = { id: sessionStorage.getItem('id') };
    // const apiUrl = 'http://localhost:8080/member/getMemberInfo';
    const apiUrl = 'https://site.yeonisoft.com/member/getMemberInfo';
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        console.log(response.data);
        // sessionStorage.setItem('token', response.data.token);
        sessionStorage.setItem('mberNm', response.data.mberNm);
        sessionStorage.setItem('mberEmailAdres', response.data.mberEmailAdres);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleLogout = () => {
    sessionStorage.clear();
    navigate('/login');
  };
  const handleLogin = () => {
    sessionStorage.removeItem('token');
    navigate('/login');
  };

  useEffect(() => {
    if (sessionStorage.getItem('token') === undefined || sessionStorage.getItem('token') === null) {
      sessionStorage.clear();
      navigate('/dashboard/app');
    } else if (
      sessionStorage.getItem('token') !== undefined ||
      sessionStorage.getItem('token') !== null
    ) {
      getData();
    }
  }, []);
  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {/* {account.displayName} */}
            {sessionStorage.getItem('mberNm')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {/* {account.email} */}
            {sessionStorage.getItem('mberEmailAdres')}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Iconify
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          {sessionStorage.getItem('token') === null ||
          sessionStorage.getItem('token') === undefined ? (
            <Button fullWidth color="inherit" variant="outlined" onClick={handleLogin}>
              로그인
            </Button>
          ) : (
            <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
              로그아웃
            </Button>
          )}
        </Box>
      </MenuPopover>
    </>
  );
}
