import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
// import { Box, Card, Link, Container, Typography } from '@mui/material';
import { Box, Link, Container, Typography } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { FindIdForm, FindPasswordForm } from '../sections/authentication/login';
// import AuthSocial from '../sections/authentication/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

// const SectionStyle = styled(Card)(({ theme }) => ({
//   width: '100%',
//   maxWidth: 464,
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   margin: theme.spacing(2, 0, 2, 2)
// }));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function FindIdPassword() {
  return (
    <RootStyle title="아이디비밀번호찾기">
      <AuthLayout>
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/">
          홈으로
        </Link>
      </AuthLayout>
      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography align="center" variant="h3" gutterBottom>
              아이디 찾기
            </Typography>
          </Box>
          <FindIdForm />
        </ContentStyle>
      </Container>
      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography align="center" variant="h3" gutterBottom>
              비밀번호 찾기
            </Typography>
          </Box>
          <FindPasswordForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
