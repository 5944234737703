import * as Yup from 'yup';
// import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
// import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function FindPasswordForm() {
  // const navigate = useNavigate();
  // const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    id: Yup.string()
      .min(2, '아이디를 2자이상으로 입력해주세요')
      .max(50, '아이디를 50자이하로 입력해주세요')
      .required('아이디를 입력해주세요'),
    username: Yup.string().required('이름을 입력해주세요'),
    email: Yup.string().email('Email 주소가 아닙니다').required('Email을 입력해주세요'),
    password: Yup.string().required('비밀번호를 입력해주세요'),
    passwordCheck: Yup.string().required('비밀번호를 한번 더 입력해주세요')
  });

  const formik = useFormik({
    initialValues: {
      id: '',
      username: '',
      companyName: '',
      email: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      // navigate('/', { replace: true });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={1}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
            <TextField
              fullWidth
              label="ID"
              {...getFieldProps('id')}
              error={Boolean(touched.id && errors.id)}
              helperText={touched.id && errors.id}
            />

            <TextField
              fullWidth
              label="회사명"
              {...getFieldProps('companyName')}
              error={Boolean(touched.companyName && errors.companyName)}
              helperText={touched.companyName && errors.companyName}
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="username"
            type="name"
            label="이름"
            {...getFieldProps('username')}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
          />

          <TextField
            fullWidth
            autoComplete="email"
            type="email"
            label="이메일"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            비밀번호 찾기
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
