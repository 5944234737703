// import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Container, Link } from '@mui/material';
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import {
  ConstructionFirstCell,
  ConstructionSecondCell,
  ConstructionThirdCell
} from '../sections/construction';
// import DataGrid from './DataGrid';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 600,
  // marginLeft: '35%',
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(1, 0)
}));

const ContentStyle2 = styled('div')(({ theme }) => ({
  // maxWidth: 600,
  // marginright: '35%',
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(17, 0)
}));

export default function RecuitmentNoticeRegistration() {
  return (
    <RootStyle title="모집공고등록">
      <AuthLayout>
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/">
          홈으로
        </Link>
      </AuthLayout>
      <Container spacing={1}>
        <ContentStyle>
          <ConstructionFirstCell />
        </ContentStyle>
      </Container>
      <Container>
        <ContentStyle2>
          <ConstructionSecondCell />
          <ConstructionThirdCell />
        </ContentStyle2>
      </Container>
    </RootStyle>
  );
}
