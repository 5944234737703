// import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
// import { Box, Card, Link, Container, Typography } from '@mui/material';
import { Box, Link, Container, Typography, Stack, TextField, Button } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import {
  RegisterForm,
  RegisterCertificateForm,
  RegisterEquipmentForm
} from '../sections/authentication/register';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  // marginLeft: '35%',
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(11, 0)
}));

const ContentStyle2 = styled('div')(({ theme }) => ({
  // maxWidth: 600,
  // marginright: '35%',
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(17, 0)
}));

// ----------------------------------------------------------------------

export default function Register() {
  // -----------------------------------------------------------------------------
  // const { errors, touched, handleSubmit, isSubmitting } = formik;
  return (
    <RootStyle title="회원가입">
      <AuthLayout>
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/">
          홈으로
        </Link>
        이미 가입을 하셨다면?&nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/login">
          로그인&nbsp;
        </Link>
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/CompanyRegister">
          기업회원가입&nbsp;
        </Link>
      </AuthLayout>

      <Container>
        <Box padding={10}>
          <Box sx={{ mb: 1 }}>
            <Typography align="center" variant="h3" gutterBottom>
              회원가입
            </Typography>
          </Box>
          <RegisterForm />
        </Box>
      </Container>
      <Container>
        <ContentStyle2>
          <Box sx={{ mb: 1 }}>
            <Typography align="center" variant="h4" gutterBottom>
              회원가입 추가정보
            </Typography>
          </Box>

          <RegisterCertificateForm />
          <br />
          <RegisterEquipmentForm />
        </ContentStyle2>
      </Container>
    </RootStyle>
  );
}
