import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { TextField, Stack, Button, Container, Typography } from '@mui/material';
// components
import { DataGrid } from '@mui/x-data-grid';
// import { DataGridPro } from '@mui/x-data-grid-pro';
// import { GridCellParams, GridCellValue, GridCellProps } from '@material-ui/data-grid';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Page from '../components/Page';
// import SearchNotFound from '../components/SearchNotFound';
// ----------------------------------------------------------------------
// 순서
// 클래스의 경우 -> constructor -> ref -> componentDidMount
// -> (setState/props 바뀔때 -> shouldComponentUpdate -> render -> componentDidUpdate)
// 부모가 나를 없앴을 때 -> componentWillUnmount -> 소멸
// componentDidMount() { 컴포넌트가 첫 렌더링된 후
// };
// componentDidUpdate() { 리렌더링 후
// };
// componentWillUnmount() { 컴포넌트가 제거되기 직전
// };
// ----------------------------------------------------------------------
// function handleSearch() {

// }
export default function Construction() {
  console.log(sessionStorage.getItem('token'));
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [division, setDivision] = useState(null);
  const [searchWord, setSearchWord] = useState('');
  // 한달전 날짜 설정
  const oneMonthAgo = new Date();
  const year = oneMonthAgo.getFullYear();
  const month = oneMonthAgo.getMonth();
  const day = oneMonthAgo.getDate();
  const [startDate, setStartDate] = useState(new Date(year, month - 1, day));
  const [endDate, setEndDate] = useState(new Date());
  const columns = [
    { field: 'id', headerName: '번호', align: 'center', width: 10 },
    { field: 'cntrwkmNm', headerName: '현장', alignRight: false, width: 150 },
    { field: 'cntrwkmDailyDate', headerName: '작성일', type: 'date', alignRight: false },
    { field: 'fairNm', headerName: '공정', alignRight: false },
    { field: 'workKdd', headerName: '구분', alignRight: false },
    { field: 'workEntrps', headerName: '업체', alignRight: false },
    { field: 'workPrdlst', headerName: '이름', alignRight: false },
    { field: 'workAmount', headerName: '공급가', type: 'number', alignRight: false },
    { field: 'workTxtamt', headerName: '세액', type: 'number', alignRight: false },
    { field: 'workTotamt', headerName: '합계', type: 'number', alignRight: false },
    { field: 'workRm', headerName: '비고', align: 'left', width: 200 },
    { field: 'cntrwkmNo', headerName: 'cntrwkmNo', align: 'center', hide: true },
    { field: 'cntrwkmDailyNo', headerName: 'cntrwkmDailyNo', align: 'center', hide: true },
    { field: 'fairNo', headerName: 'fairNo', align: 'center', hide: true }
  ];
  // setToken(sessionStorage.getItem('token'));
  // -----------------------------------------------API 호출-----------------------------------------------------
  const getData = async () => {
    // const apiUrl = 'http://localhost:8080/cntrwkm/getTotalList';
    const apiUrl = 'https://site.yeonisoft.com/cntrwkm/getTotalList';
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      cntrwkmNm: searchWord,
      cntrwkmSDate: '2000-01-01',
      // cntrwkmSDate: startDate,
      cntrwkmEDate: '9999-12-31',
      // cntrwkmEDate: endDate
      workKdd: division
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        setList(response.data);
        console.log(list);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // -----------------------------------------------API 호출-----------------------------------------------------
  // -----------------------------------------------useEffect----------------------------------------------------
  useEffect(() => {
    // 토큰 값이 없을 경우 Login Page로 이동
    if (sessionStorage.getItem('token') === undefined || sessionStorage.getItem('token') === null) {
      navigate('/login');
    }
    getData();
    // if (!list) return <SearchNotFound />;
    // console.log(list);
  }, []);
  // ------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------Event-------------------------------------------------------
  const onCellDoubleClick = (params) => {
    console.log(params.row);
  };
  const onClickSearch = (e) => {
    setSearchWord(e.target.value);
    // setStartDate();
    // setEndDate();
    console.log(e.target.value);
    getData();
  };
  const onChangeKeyword = (e) => {
    setSearchWord(e.target.value);
  };
  const onChangeDivision = (e) => {
    setDivision(e.target.value);
  };
  const onClick = (params) => {
    console.log(params);
  };
  // -----------------------------------------------------------------------------------------------------------
  return (
    <Page title="공사관리">
      <Container spacing={1}>
        <Stack justifyContent="flex-start">
          <Typography variant="h3" gutterBottom>
            공사관리
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0.5}>
          <Stack direction="row" spacing={1}>
            <TextField
              fullWidth
              label="현장명"
              type="search"
              size="small"
              value={searchWord}
              onChange={onChangeKeyword}
              // onKeyDown={onkeydown}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">구분</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={division}
                label="구분"
                size="small"
                onChange={onChangeDivision}
              >
                <MenuItem value="자재비">자재비</MenuItem>
                <MenuItem value="인건비">인건비</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack direction="row" spacing={1}>
              <DatePicker
                // sx={{ ml: 1, width: 20, height: 20 }}
                // size="small"
                mask="____-__-__"
                value={startDate}
                inputFormat="yyyy-MM-dd"
                selectStart
                // onChange={(newValue) => setStartDate(newValue)}
                onChange={(e) => setStartDate(e.target.value)}
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                // sx={{ ml: 1, width: 20, height: 20 }}
                // size="small"
                mask="____-__-__"
                value={endDate}
                inputFormat="yyyy-MM-dd"
                selectEnd
                // onChange={(newValue) => setEndDate(newValue)}
                onChange={(e) => setEndDate(e.target.value)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          </LocalizationProvider> */}
          <Stack direction="row" spacing={1} mb={1}>
            <Button
              variant="outlined"
              component={RouterLink}
              to="/ConstructorRegistration"
              size="small"
            >
              등록
            </Button>
            <Button variant="contained" value={searchWord} onClick={onClickSearch}>
              검색
            </Button>
          </Stack>
        </Stack>
      </Container>
      <div style={{ height: 600, width: '100%' }}>
        <DataGrid
          // {...GridCellParams}
          // {...GridCellValue}
          rows={list}
          columns={columns}
          onCellClick={onCellDoubleClick}
          disableColumnMenu
        />
      </div>
    </Page>
  );
}
