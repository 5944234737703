// import { filter } from 'lodash';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer
} from '@mui/material';
// component
import RegisterMoreHead from './RegisterMoreHead';
import RegisterMoreMenu from './RegisterMoreMenu';
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'certificateName', label: '자격증명', alignRight: false },
  { id: 'agency', label: '발급기관', alignRight: false },
  { id: 'firstDate', label: '취득일', alignRight: false },
  { id: 'checkDate', label: '적성검사기간', alignRight: false },
  { id: '' }
];

function createData(no, certificateName, agency, firstDate, checkDate) {
  return { no, certificateName, agency, firstDate, checkDate };
}

const certificateList = [];

export default function RegisterCertificateForm() {
  // const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  // const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = certificateList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  return (
    // 추가 삭제 Button
    <Container spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography variant="h6" gutterBottom align="left">
            보유 자격증
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          <Button variant="outlined" component={RouterLink} to="#">
            추가
          </Button>
          <Button variant="outlined">삭제</Button>
        </Stack>
      </Stack>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <RegisterMoreHead
            // order={order}
            headLabel={TABLE_HEAD}
            rowCount={certificateList.length}
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
          />
          <TableBody>
            {certificateList.map((row) => {
              const { no, certificateName, agency, firstDate, checkDate } = row;
              const isItemSelected = selected.indexOf(no) !== -1;
              console.log(certificateList);
              console.log(row);

              return (
                <TableRow
                  hover
                  key={no}
                  tabIndex={-1}
                  role="checkbox"
                  selected={isItemSelected}
                  aria-checked={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      onChange={(event) => handleClick(event, no)}
                    />
                  </TableCell>
                  <TableCell align="left">{certificateName}</TableCell>
                  <TableCell align="left">{agency}</TableCell>
                  <TableCell align="left">{firstDate}</TableCell>
                  <TableCell align="left">{checkDate}</TableCell>
                  <TableCell align="center">
                    <RegisterMoreMenu />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
