import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Stack, Link, Container, Typography, TextField, Button } from '@mui/material';
// import { Card, Stack, Link, Container, Typography } from '@mui/material';
// layouts
import axios from 'axios';
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { LoginForm } from '../sections/authentication/login';

// import AuthSocial from '../sections/authentication/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// --------------------------------------------------------------------------------------------------------------
export default function Login() {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('mberNm');
  sessionStorage.removeItem('mberEmail');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [userId, setUserId] = useState();
  const [userPassword, setUserPassword] = useState();
  const [error, setError] = useState('');
  const [loginStateCode, setLoginStateCode] = useState();
  // -----------------------------------------------API 호출-----------------------------------------------------
  const getData = async () => {
    const apiUrl = 'https://site.yeonisoft.com/login';
    // const apiUrl = 'http://localhost:8080/login';
    const sendParams = {
      id: userId,
      pw: userPassword
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams)
      .then((response) => {
        console.log(response.data);
        sessionStorage.setItem('token', response.data.token);
        sessionStorage.setItem('Bearer', response.data.token);
        sessionStorage.setItem('id', response.data.id);
        if (response.data.connect === 'SUCCESS') {
          navigate('/dashboard/app');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // -----------------------------------------------API 호출-----------------------------------------------------
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const onChangeUserid = (e) => {
    setUserId(e.target.value);
  };
  const onChangePassword = (e) => {
    setUserPassword(e.target.value);
  };
  const onClickLogin = () => {
    if (
      userId === '' ||
      userPassword === '' ||
      userId === undefined ||
      userPassword === undefined
    ) {
      setError('아이디 또는 비밀번호가 필요합니다.');
      return;
    }
    getData();
  };
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      onClickLogin();
    }
  };
  return (
    <RootStyle title="로그인">
      <AuthLayout>
        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          <Link underline="none" variant="subtitle2" component={RouterLink} to="/">
            홈으로
          </Link>
          &nbsp;&nbsp;아직 가입이 안되어있으시다면? &nbsp;
          <Link underline="none" variant="subtitle2" component={RouterLink} to="/register">
            회원가입
          </Link>
        </Stack>
      </AuthLayout>
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography align="center" variant="h3" gutterBottom>
              로그인
            </Typography>
          </Stack>
          <form>
            <Stack spacing={3}>
              <TextField fullWidth autoComplete="id" label="아이디" onChange={onChangeUserid} />

              <TextField
                fullWidth
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                label="비밀번호"
                onChange={onChangePassword}
                onKeyPress={onKeyPress}
              />
            </Stack>
          </form>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              // loading={isSubmitting}
              onClick={onClickLogin}
            >
              로그인
            </Button>
          </Stack>
          <Stack>
            <Link component={RouterLink} variant="subtitle2" to="/findIdPassword" underline="hover">
              아이디 또는 비밀번호를 잃어버리셨다면?
            </Link>
          </Stack>
          <Typography
            variant="body2"
            align="center"
            sx={{
              mt: 3,
              display: { sm: 'none' }
            }}
          >
            계정이 없으시다면?&nbsp;
            <Link variant="subtitle2" component={RouterLink} to="register" underline="hover">
              회원가입
            </Link>
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
