import * as Yup from 'yup';
import { useState, useCallback } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import axios from 'axios';
import { Stack, TextField, Button, InputAdornment, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;

  const RegisterSchema = Yup.object().shape({
    id: Yup.string().required('아이디를 입력해주세요'),
    username: Yup.string().required('이름을 입력해주세요'),
    ncm: Yup.string().required('닉네임을 입력해주세요'),
    email: Yup.string().email('Email 주소가 아닙니다').required('Email을 입력해주세요'),
    phoneNum: Yup.string().required('이름을 입력해주세요'),
    password: Yup.string()
      .required('비밀번호를 입력해주세요')
      .min(7, '8자 이상 입력해주세요')
      .matches(passwordRegex, {
        message: '숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!'
      }),
    passwordCheck: Yup.string()
      .required('비밀번호를 한번 더 입력해주세요')
      .oneOf([Yup.ref('password'), null], '비밀번호가 일치하지 않습니다.')
  });
  const formik = useFormik({
    initialValues: {
      id: '',
      username: '',
      ncm: '',
      phoneNum: '',
      email: '',
      password: '',
      passwordCheck: ''
    },
    // validationSchema: RegisterSchema
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      console.log(values);
      // 비밀번호
      saveApiCall();
    }
  });
  // -------------------------------------------------------------------------------------
  const saveApiCall = async () => {
    const apiUrl = 'http://localhost:8080/member/regMember';
    const sendParams = {
      mberNm: formik.values.username,
      id: formik.values.userId,
      ncm: formik.values.nickName,
      password: formik.values.password,
      mbtlnum: formik.values.phoneNum,
      mberEmailAdres: formik.values.email
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams)
      .then((response) => {
        console.log(response.code);
        if (response.code === '200') {
          window.alert('회원가입 되셨습니다');
          navigate('/login');
        }
        if (response.code === '500') {
          window.alert('이미사용중인 아이디가 있습니다');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const checkIdApi = async () => {
    const apiUrl = 'https://site.yeonisoft.com/member/regMember';
    // const apiUrl = 'http://localhost:8080/member/checkId';
    const sendParams = {
      id: formik.values.id
    };
    axios
      .post(apiUrl, sendParams)
      .then((response) => {
        console.log(response.data);
        if (response.data.code === '200') {
          // console.log('성공');
          window.alert('사용가능한 아이디 입니다.');
        }
        if (response.data.code === '500') {
          window.alert('이미사용중인 아이디가 있습니다');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const checkNicknameApi = async () => {
    const apiUrl = `http://site.yeonisoft.com/member/checkNcm/${formik.values.ncm}`;
    // const apiUrl = `http://localhost:8080/member/checkNcm/${formik.values.ncm}`;
    axios
      .post(apiUrl)
      .then((response) => {
        console.log(response.data);
        if (response.data.code === '200') {
          // console.log('성공');
          window.alert('사용가능한 닉네임 입니다.');
        }
        if (response.data.code === '500') {
          window.alert('이미사용중인 닉네임이 있습니다');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // const validate = useCallback((initialValues) => {
  //   const errorMsg = {};
  //   const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
  //   if (!passwordRegex.test(initialValues.password)) {
  //     // window.alert('숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!');
  //     errorMsg.password = '숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!';
  //   }
  //   errorMsg.password = '안전한 비밀번호입니다';
  //   return errorMsg;
  // }, []);
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={1.5}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
            <TextField
              fullWidth
              size="small"
              label="ID"
              {...getFieldProps('id')}
              error={Boolean(touched.id && errors.id)}
              helperText={touched.id && errors.id}
              // onChange={onChangeId}
            />
            <Button fullWidth size="small" variant="contained" onClick={checkIdApi}>
              중복확인
            </Button>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
            <TextField
              fullWidth
              size="small"
              label="닉네임"
              type="ncm"
              {...getFieldProps('ncm')}
              error={Boolean(touched.ncm && errors.ncm)}
              helperText={touched.ncm && errors.ncm}
              // onChange={onChangeNickname}
              value={formik.values.ncm}
            />
            <Button fullWidth size="small" variant="contained" onClick={checkNicknameApi}>
              중복확인
            </Button>
          </Stack>

          <TextField
            fullWidth
            size="small"
            autoComplete="username"
            type="username"
            label="이름"
            {...getFieldProps('username')}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
            // onChange={onChangeName}
            value={formik.values.username}
          />

          <TextField
            fullWidth
            size="small"
            autoComplete="phoneNum"
            type="phoneNum"
            label="전화번호"
            {...getFieldProps('phoneNum')}
            error={Boolean(touched.phoneNum && errors.phoneNum)}
            helperText={touched.phoneNum && errors.phoneNum}
            // onChange={onChangeTel}
            value={formik.values.phoneNum}
          />

          <TextField
            fullWidth
            size="small"
            autoComplete="email"
            type="email"
            label="이메일"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            // onChange={onChangeEmail}
            value={formik.values.email}
          />

          <TextField
            fullWidth
            size="small"
            autoComplete="new-password"
            // type={showPassword ? 'text' : 'password'}
            type="password"
            label="비밀번호"
            {...getFieldProps('password')}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            // onChange={validate}
            value={formik.values.password}
          />
          <TextField
            fullWidth
            size="small"
            autoComplete="new-password"
            // type={showPassword ? 'text' : 'password'}
            type="password"
            label="비밀번호 확인"
            {...getFieldProps('passwordCheck')}
            error={Boolean(touched.passwordCheck && errors.passwordCheck)}
            helperText={touched.passwordCheck && errors.passwordCheck}
            // onChange={onChangeCheckPw}
            value={formik.values.passwordCheck}
          />
          <Typography>
            ※ 비밀번호는 최소 8자 이상 최대 24자 이하
            <br />
            ※ 숫자/영어 대소문자/특수문자 모두 포함
            <br />
            ※ 사용가능 특수문자 : ! @ # $ % ^ & + =
            <br />
          </Typography>
          <LoadingButton
            fullWidth
            size="medium"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            회원가입
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
