// material
// import { Box, Grid, Container, Typography } from '@mui/material';
import { Grid, Container, Alert, AlertTitle, Stack } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// components
import Page from '../components/Page';
import {
  AppCurrentVisits,
  AppWebsiteVisits,
  AppNotice,
  AppNews,
  AppRecrumentNotice
} from '../sections/@dashboard/app';

export default function DashboardApp() {
  console.log('dashboard');
  return (
    <Page title="건설의 달인">
      {/* <Stack sx={{ width: '30%' }} spacing={2} alignItems="left">
        <Alert severity="success">
          <AlertTitle>로그인</AlertTitle>
          로그인되었습니다
        </Alert>
      </Stack> */}
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={4}>
            <AppRecrumentNotice />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AppNotice />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AppNews />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
