import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Stack, Link, Card, Button, Divider, Typography, CardHeader } from '@mui/material';
// utils
import axios from 'axios';
import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
// ----------------------------------------------------------------------
export default function AppRecrumentNotice() {
  const [workList, setWorkList] = useState([]);
  // -----------------------------------------API 호출--------------------------------------------------
  const getData = async () => {
    // const apiUrl = 'http://localhost:8080/getWorkListTop5';
    const apiUrl = 'https://site.yeonisoft.com/getWorkListTop5';
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl)
      .then((response) => {
        setWorkList(response.data);
        NewsItem(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // --------------------------------------------------------------------------------------------------
  // ------------------------------------------useEffect-----------------------------------------------
  useEffect(() => {
    getData();
  }, []);
  // --------------------------------------------------------------------------------------------------
  return (
    <Card>
      <CardHeader title="모집공고" />

      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {workList.map((workList) => (
            <NewsItem key={workList.workNo} workList={workList} />
          ))}
        </Stack>
      </Scrollbar>

      <Divider />

      <Box sx={{ p: 0.5, textAlign: 'right' }}>
        <Button
          to="/dashboard/RecruitmentNotice"
          size="small"
          color="inherit"
          component={RouterLink}
          endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
        >
          전체보기
        </Button>
      </Box>
    </Card>
  );
}

function NewsItem({ workList }) {
  const { workNm, workPlace, regDate, workCn, workAdres, pymntMthd } = workList;

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Box sx={{ minWidth: 240 }}>
        <Link to="#" color="inherit" underline="hover" component={RouterLink}>
          <Typography variant="subtitle2" noWrap>
            {workNm} {workCn}
          </Typography>
        </Link>
        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {workAdres} ({pymntMthd})
        </Typography>
      </Box>
      <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
        {regDate.substr(0, 16)}
      </Typography>
    </Stack>
  );
}
