import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer
} from '@mui/material';
// component
import RegisterMoreHead from './RegisterMoreHead';
import RegisterMoreMenu from './RegisterMoreMenu';
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'equipment', label: '장비종류', alignRight: false },
  { id: 'makeYear', label: '연식', alignRight: false },
  { id: 'serialNo', label: '장비번호', alignRight: false },
  { id: '' }
];

function createData(no, equipment, makeYear, serialNo) {
  return { no, equipment, makeYear, serialNo };
}

const equipmentList = [];

export default function RegisterEquipmentForm() {
  // const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  // const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = equipmentList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  return (
    // 추가 삭제 Button
    <Container spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography variant="h6" gutterBottom align="left">
            보유 장비
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          <Button variant="outlined" component={RouterLink} to="#">
            추가
          </Button>
          <Button variant="outlined" component={RouterLink} to="#">
            삭제
          </Button>
        </Stack>
      </Stack>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <RegisterMoreHead
            // order={order}
            headLabel={TABLE_HEAD}
            rowCount={equipmentList.length}
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
          />
          <TableBody>
            {equipmentList.map((row) => {
              const { no, equipment, makeYear, serialNo } = row;
              const isItemSelected = selected.indexOf(no) !== -1;
              console.log(equipmentList);
              console.log(row);

              return (
                <TableRow
                  hover
                  key={no}
                  tabIndex={-1}
                  role="checkbox"
                  selected={isItemSelected}
                  aria-checked={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      onChange={(event) => handleClick(event, no)}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <Stack direction="row">
                      <Typography variant="subtitle2" noWrap>
                        {no}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="left">{equipment}</TableCell>
                  <TableCell align="left">{makeYear}</TableCell>
                  <TableCell align="left">{serialNo}</TableCell>
                  <TableCell align="center">
                    <RegisterMoreMenu />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
