import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
// material
import { TextField, Stack, Button, Typography } from '@mui/material';
// import { DataGrid } from '@material-ui/data-grid';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { ConstructionListHead, ConstructionDetail } from '.';

const TABLE_HEAD = [
  { field: 'id', headerName: '번호', width: 50 },
  { field: 'sceneName', headerName: '현장명', width: 150 },
  { field: 'date', headerName: '공사기간', width: 120 },
  { field: 'status', headerName: '상태', width: 100 }
];

export default function ConstructionFirstCell() {
  const [constructionList, setConstructionList] = useState([]);
  const [sceneName, setSceneName] = useState();
  const [startDate, setStarDate] = useState();
  const [endDate, setEndDate] = useState();
  const [status, setStatus] = useState('working');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [saveData, setSaveData] = useState({
    cntrwkmNm: '',
    cntrwkmSDate: '',
    cntrwkmEDate: '',
    cntrwkmSttus: '',
    cntrwkRegDate: ''
  });
  // ---------------------------------------------------------------------
  // API 호출
  const getData = async () => {
    const apiUrl = 'http://localhost:8080/cntrwkm/getCntrwkmList';
    // const sendParams = {
    //   cntrwkmNm: '',
    //   cntrwkmSDate: '2000-01-01',
    //   cntrwkmEDate: '9999-12-31'
    // };
    axios
      .post(apiUrl)
      .then((response) => {
        setConstructionList(response.data);
        console.log(constructionList);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // ---------------------------------------------------------------------
  // Dialog Open
  const handleClickOpen = () => {
    setDialogOpen(true);
    setSaveData({
      // id: '',
      cntrwkmNm: sceneName,
      cntrwkmSDate: startDate,
      cntrwkmEDate: endDate,
      cntrwkmSttus: status,
      cntrwkRegDate: 'regUser'
    });
  };
  // Dialog Close
  const handleClickClose = () => {
    setSceneName('');
    setStarDate('');
    setEndDate('');
    setStatus('');
    setDialogOpen(false);
  };
  // ----------------------------------------------------------------------
  // popup 내용 저장 --------------------------------------------------------
  const handleDialogSave = () => {
    // console.log(createData);
    console.log(constructionList);
    console.log(constructionList.length);
    setConstructionList({
      id: constructionList.length + 1,
      cntrwkmNm: sceneName,
      cntrwkmSDate: startDate,
      cntrwkmEDate: endDate,
      cntrwkmSttus: status
    });
    handleClickClose();
  };
  // ------------------------------------------------------------------------
  // ----------
  const handleApiSave = () => {
    console.log('SAVE API CALL');
    // getData();
  };
  // ------------------------------------------------------------------------
  // Parameter Save----------------------------------------------------------
  const onChangeScenename = (e) => {
    setSceneName(e.target.value || '');
  };
  const onChangeStartDate = (e) => {
    setStarDate(e.target.value || '');
  };
  const onChangeEndDate = (e) => {
    setEndDate(e.target.value || '');
  };
  const onChangeStatus = (e) => {
    setStatus(e.target.value || '');
  };
  const onCellDoubleClick = (params) => {
    console.log(params.row);
  };
  // ----------------------------------------------------------------------
  // useEffect
  useEffect(() => {
    getData();
  }, []);
  // ----------------------------------------------------------------------
  // ----------------------------------------------------------------------
  return (
    <div style={{ height: 400, width: '100%' }}>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography variant="h5" gutterBottom align="left">
            공사 현장명
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          <Button variant="outlined" onClick={handleClickOpen}>
            추가
          </Button>
          <Button variant="outlined" onClick={handleApiSave}>
            저장
          </Button>
          <Dialog
            open={dialogOpen}
            onClose={handleClickClose}
            PaperProps={{ sx: { width: '30%', height: '50%' } }}
          >
            <DialogTitle align="center">공사현장 추가</DialogTitle>
            <DialogContent spacing={2}>
              <Stack direction="column" justifyContent="space-between" spacing={3}>
                <TextField
                  label="현장명"
                  type="text"
                  variant="standard"
                  name="sceneName"
                  value={sceneName || ''}
                  onChange={onChangeScenename}
                />
                <TextField
                  type="date"
                  variant="standard"
                  name="workStartDate"
                  value={startDate || ''}
                  onChange={onChangeStartDate}
                />
                <TextField
                  type="date"
                  variant="standard"
                  name="workEndDate"
                  value={endDate || ''}
                  onChange={onChangeEndDate}
                />
                <FormControl>
                  <FormLabel>상태</FormLabel>
                  <RadioGroup>
                    <FormControlLabel
                      checked={status === 'working'}
                      onChange={onChangeStatus}
                      value="working"
                      control={<Radio />}
                      label="공사중"
                    />
                    <FormControlLabel
                      checked={status === 'worked'}
                      onChange={onChangeStatus}
                      value="worked"
                      control={<Radio />}
                      label="마감"
                    />
                  </RadioGroup>
                </FormControl>
                {/* <TextField Label="비고" type="text" name="etc"value={sceneName} /> */}
              </Stack>
              <Stack direction="row" justifyContent="center" spacing={1}>
                <Button variant="contained" onClick={handleDialogSave}>
                  저장
                </Button>
                <Button variant="contained" onClick={handleClickClose}>
                  취소
                </Button>
              </Stack>
            </DialogContent>
          </Dialog>
          <Button variant="contained">삭제</Button>
        </Stack>
      </Stack>
      <DataGrid rows={constructionList} columns={TABLE_HEAD} onCellClick={onCellDoubleClick} />
    </div>
  );
}
